import React, { useState, useEffect } from 'react';
import { Workflow, Check, Play, User, Clock, AlertCircle, Edit, RefreshCw, X } from 'lucide-react';
import Lightbox from '../shared/Lightbox';
import { db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';

const ReviewWorkflowSection = ({ 
  workflow, 
  currentStep, 
  caseType, 
  onStartWorkflow, 
  onApproveStep, 
  approvals, 
  currentUser,
  currentApprover,
  onChangeWorkflow, 
  availableWorkflows, 
  users = []
}) => {
  const [userNames, setUserNames] = useState({});
  
  useEffect(() => {
    console.log('Users prop in ReviewWorkflowSection:', users);
    const fetchUserNames = async () => {
      const userIds = workflow?.steps.map(step => step.approver) || [];
      const uniqueUserIds = [...new Set(userIds)];
      
      const names = {};
      for (const userId of uniqueUserIds) {
        const userRef = doc(db, 'users', userId);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          const userData = userSnap.data();
          names[userId] = `${userData.firstName} ${userData.lastName}`;
        } else {
          names[userId] = 'Unknown User';
        }
      }
      setUserNames(names);
    };

    fetchUserNames();
  }, [workflow, users]);

  const getUserName = (userId) => {
    return userNames[userId] || 'Unknown User';
  };

  const handleChangeWorkflow = () => {
    onChangeWorkflow(selectedWorkflowId);
    setIsChangingWorkflow(false);
    setSelectedWorkflowId('');
  };

  const handleStartWorkflow = () => {
    console.log('handleStartWorkflow called with users:', users);
    if (users && users.length > 0) {
      onStartWorkflow(users);
    } else {
      console.error('Users data is not available');
      // You might want to show an error message to the user here
    }
  };

  const [isChangingWorkflow, setIsChangingWorkflow] = useState(false);
  const [selectedWorkflowId, setSelectedWorkflowId] = useState('');

  return (
    <div className="bg-white shadow rounded-lg p-4 sm:p-6 overflow-hidden">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-base sm:text-lg font-medium text-gray-900 flex items-center min-w-0">
          <Workflow className="w-5 h-5 sm:w-6 sm:h-6 mr-2 flex-shrink-0 text-indigo-500" />
          <span className="truncate">Review Workflow: {caseType}</span>
        </h3>
        <div className="flex items-center">
          {workflow && currentStep === -1 && (
            <button
              onClick={handleStartWorkflow}
              disabled={!users || users.length === 0}
              className="inline-flex items-center px-2 sm:px-3 py-1 border border-transparent text-xs sm:text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 flex-shrink-0 mr-2 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <Play className="w-4 h-4 sm:w-5 sm:h-5 mr-1" />
              Start Workflow
            </button>
          )}
          <button
            onClick={() => setIsChangingWorkflow(true)}
            className="inline-flex items-center px-2 sm:px-3 py-1 border border-transparent text-xs sm:text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 flex-shrink-0 ml-2"
          >
            <Edit className="w-4 h-4 sm:w-5 sm:h-5 mr-1" />
            Change Workflow
          </button>
        </div>
      </div>
      
      {workflow ? (
        <div className="space-y-4 mb-4">
          {workflow.steps.map((step, index) => {
            const isApproved = approvals.some(approval => approval.step === index);
            const isNotStarted = currentStep === -1;
            const isCurrentUserApprover = step.approver === currentUser?.uid;
            
            return (
              <div key={index} className={`flex items-center ${index === currentStep ? 'bg-blue-50' : ''} p-2 rounded-md`}>
                <div className={`w-8 h-8 rounded-full flex items-center justify-center ${index < currentStep ? 'bg-green-500' : index === currentStep ? 'bg-blue-500' : 'bg-gray-300'}`}>
                  {index < currentStep ? (
                    <Check className="w-5 h-5 text-white" />
                  ) : (
                    <span className="text-white font-medium">{index + 1}</span>
                  )}
                </div>
                <span className="ml-3 text-sm font-medium text-gray-900 flex items-center">
                  <User className="w-4 h-4 mr-1 text-gray-500" />
                  {getUserName(step.approver)}
                </span>
                <span className={`ml-2 px-2 py-1 text-xs font-medium rounded-full ${
                  isNotStarted ? 'bg-gray-100 text-gray-800' :
                  isApproved ? 'bg-green-100 text-green-800' : 'bg-yellow-100 text-yellow-800'
                }`}>
                  {isNotStarted ? (
                    <>
                      <Clock className="w-3 h-3 inline mr-1" />
                      Not Started
                    </>
                  ) : isApproved ? (
                    <>
                      <Check className="w-3 h-3 inline mr-1" />
                      Approved
                    </>
                  ) : (
                    <>
                      <Clock className="w-3 h-3 inline mr-1" />
                      Pending
                    </>
                  )}
                </span>
                {index === currentStep && isCurrentUserApprover && !isApproved && (
                  <button
                    onClick={onApproveStep}
                    className="ml-auto bg-green-500 hover:bg-green-600 text-white py-1 px-3 rounded-md text-sm transition duration-150 ease-in-out"
                  >
                    Approve
                  </button>
                )}
              </div>
            );
          })}
        </div>
      ) : (
        <div className="text-center py-4 bg-gray-50 rounded-lg">
          <div className="flex items-center justify-center text-yellow-400">
            <AlertCircle className="w-5 h-5 mr-2" />
            <h3 className="text-sm font-medium text-gray-900">No workflow assigned</h3>
          </div>
          <p className="text-xs text-gray-500 mt-1">
            Please select a workflow using the 'Change Workflow' button.
          </p>
        </div>
      )}

      <Lightbox
        isOpen={isChangingWorkflow}
        onClose={() => {
          setIsChangingWorkflow(false);
          setSelectedWorkflowId('');
        }}
        title="Change Workflow"
      >
        <div className="bg-white rounded-lg overflow-hidden">
          <div className="p-6">
            <p className="text-sm text-gray-500 mb-4 flex items-center">
              <AlertCircle className="w-4 h-4 mr-2 text-yellow-500" />
              Selecting a new workflow will reset the current progress.
            </p>
            <div className="mb-4">
              <label htmlFor="workflow-select" className="block text-sm font-medium text-gray-700 mb-2 flex items-center">
                <Workflow className="w-4 h-4 mr-2 text-indigo-500" />
                New Workflow
              </label>
              <div className="relative">
                <select
                  id="workflow-select"
                  value={selectedWorkflowId}
                  onChange={(e) => setSelectedWorkflowId(e.target.value)}
                  className="block w-full pl-3 pr-10 py-2 text-base border-2 border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                >
                  <option value="">Select a workflow</option>
                  {availableWorkflows.map((wf) => (
                    <option key={wf.id} value={wf.id}>
                      {wf.name}
                    </option>
                  ))}
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg className="h-4 w-4" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              onClick={handleChangeWorkflow}
              disabled={!selectedWorkflowId}
              className="w-full inline-flex justify-center items-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <RefreshCw className="w-4 h-4 mr-2" />
              Change Workflow
            </button>
            <button
              onClick={() => setIsChangingWorkflow(false)}
              className="mt-3 w-full inline-flex justify-center items-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              <X className="w-4 h-4 mr-2" />
              Cancel
            </button>
          </div>
        </div>
      </Lightbox>
    </div>
  );
};

export default ReviewWorkflowSection;