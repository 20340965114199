import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import { doc, setDoc, getDoc, updateDoc, collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import { debounce } from 'lodash';
import { Plus, Edit, Trash, Calendar, ChevronDown, ChevronRight, Printer, AlertCircle, Target, TrendingUp, HelpCircle, X } from 'lucide-react';
import InvolvedPartiesSection from '../shared/InvolvedPartiesSection';
import EventTrackerSection from '../shared/EventTrackerSection';
import Lightbox from '../shared/Lightbox';
import { useReactToPrint } from 'react-to-print';
import { Menu } from '@headlessui/react';
import { Editor } from '@tinymce/tinymce-react';
import ReactDOM from 'react-dom';
import ReviewWorkflowSection from '../shared/ReviewWorkflowSection';
import { useWorkflow } from '../contexts/WorkflowContext';
import { getFunctions, httpsCallable } from 'firebase/functions';
import FileUploadSection from '../shared/FileUploadSection';

console.log('Firestore instance:', db);

const ReviewDatesSection = ({ reviewDates, onUpdate }) => {
  const [expandedReviews, setExpandedReviews] = useState({});
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [editingReviewDate, setEditingReviewDate] = useState(null);
  const [newReviewDate, setNewReviewDate] = useState({ date: '', type: '', status: '', conductor: '', notes: '' });

  const toggleReviewExpansion = (index) => {
    setExpandedReviews(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  const handleAddReviewDate = () => {
    setIsLightboxOpen(true);
    setEditingReviewDate(null);
    setNewReviewDate({ date: '', type: '', status: '', conductor: '', notes: '' });
  };

  const handleEditReviewDate = (index) => {
    setIsLightboxOpen(true);
    setEditingReviewDate(index);
    setNewReviewDate(reviewDates[index]);
  };

  const handleSaveReviewDate = () => {
    let updatedReviewDates;
    if (editingReviewDate !== null) {
      updatedReviewDates = reviewDates.map((date, i) => i === editingReviewDate ? newReviewDate : date);
    } else {
      updatedReviewDates = [...reviewDates, newReviewDate];
    }
    onUpdate(updatedReviewDates);
    setIsLightboxOpen(false);
    setEditingReviewDate(null);
    setNewReviewDate({ date: '', type: '', status: '', conductor: '', notes: '' });
  };

  const handleDeleteReviewDate = (index) => {
    const updatedReviewDates = reviewDates.filter((_, i) => i !== index);
    onUpdate(updatedReviewDates);
  };

  const statusOptions = ['Scheduled', 'Completed', 'Cancelled', 'Rescheduled'];
  const typeOptions = ['Initial Meeting', '30 Day Review', '60 Day Review', '90 Day Review', 'Final Review', 'Other'];

  const getStatusColor = (status) => {
    switch (status) {
      case 'Scheduled': return 'text-yellow-600 bg-yellow-100';
      case 'Completed': return 'text-green-600 bg-green-100';
      case 'Cancelled': return 'text-red-600 bg-red-100';
      case 'Rescheduled': return 'text-blue-600 bg-blue-100';
      default: return 'text-gray-600 bg-gray-100';
    }
  };

  return (
    <div className="bg-white shadow rounded-lg p-4 sm:p-6 overflow-hidden">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-base sm:text-lg font-medium text-gray-900 flex items-center min-w-0">
          <Calendar className="w-5 h-5 sm:w-6 sm:h-6 mr-2 flex-shrink-0 text-indigo-500" />
          <span className="truncate">Review Dates</span>
        </h3>
        <button
          onClick={handleAddReviewDate}
          className="inline-flex items-center px-2 sm:px-3 py-1 border border-transparent text-xs sm:text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 flex-shrink-0 ml-2"
        >
          <Plus className="w-4 h-4 sm:w-5 sm:h-5 mr-1" />
          Add Review Date
        </button>
      </div>
      {reviewDates && reviewDates.length > 0 ? (
        <div className="space-y-3">
          {reviewDates.map((review, index) => (
            <div key={index} className="bg-gray-50 rounded-lg p-3 overflow-hidden hover:bg-gray-100 transition-colors duration-300">
              <div className="flex items-center">
                <div className="flex-shrink-0 mr-3">
                  <Calendar className="w-5 h-5 text-indigo-500" />
                </div>
                <div className="min-w-0 flex-grow">
                  <div className="flex items-center space-x-2">
                    <p className="text-sm font-medium text-gray-900">{review.date}</p>
                    <p className="text-sm font-medium text-indigo-600">{review.type}</p>
                  </div>
                </div>
                <div className="flex space-x-2 flex-shrink-0 ml-2">
                  <button
                    onClick={() => toggleReviewExpansion(index)}
                    className="text-gray-400 hover:text-indigo-600 transition-colors duration-300"
                  >
                    {expandedReviews[index] ? (
                      <ChevronDown className="w-5 h-5" />
                    ) : (
                      <ChevronRight className="w-5 h-5" />
                    )}
                  </button>
                  <button
                    onClick={() => handleEditReviewDate(index)}
                    className="text-gray-400 hover:text-indigo-600 transition-colors duration-300"
                  >
                    <Edit className="w-5 h-5" />
                  </button>
                  <button
                    onClick={() => handleDeleteReviewDate(index)}
                    className="text-gray-400 hover:text-red-600 transition-colors duration-300"
                  >
                    <Trash className="w-5 h-5" />
                  </button>
                </div>
              </div>
              {expandedReviews[index] && (
                <div className="mt-2 pl-8">
                  <p className={`text-sm font-medium px-2 py-0.5 rounded-full inline-block mb-2 ${getStatusColor(review.status)}`}>{review.status}</p>
                  <p className="text-sm text-gray-600 mt-1">Conductor: {review.conductor}</p>
                  {review.notes && (
                    <p className="text-sm text-gray-600 mt-1"><span className="font-medium">Notes:</span> {review.notes}</p>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      ) : (
        <div className="text-center py-4 bg-gray-50 rounded-lg">
          <div className="flex items-center justify-center text-indigo-400">
            <Calendar className="w-5 h-5 mr-2" />
            <h3 className="text-sm font-medium text-gray-900">No review dates yet</h3>
          </div>
          <p className="text-xs text-gray-500 mt-1">
            Click 'Add Review Date' to start scheduling reviews.
          </p>
        </div>
      )}

      <Lightbox
        isOpen={isLightboxOpen}
        onClose={() => {
          setIsLightboxOpen(false);
          setEditingReviewDate(null);
          setNewReviewDate({ date: '', type: '', status: '', conductor: '', notes: '' });
        }}
        title={editingReviewDate !== null ? "Edit Review Date" : "Add Review Date"}
      >
        <form onSubmit={(e) => { e.preventDefault(); handleSaveReviewDate(); }}>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Date <span className="text-red-500">*</span>
            </label>
            <input
              type="date"
              value={newReviewDate.date}
              onChange={(e) => setNewReviewDate(prev => ({ ...prev, date: e.target.value }))}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Type <span className="text-red-500">*</span>
            </label>
            <select
              value={newReviewDate.type}
              onChange={(e) => setNewReviewDate(prev => ({ ...prev, type: e.target.value }))}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500"
              required
            >
              <option value="">Select a type</option>
              {typeOptions.map(option => (
                <option key={option} value={option}>{option}</option>
              ))}
            </select>
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Status <span className="text-red-500">*</span>
            </label>
            <select
              value={newReviewDate.status}
              onChange={(e) => setNewReviewDate(prev => ({ ...prev, status: e.target.value }))}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500"
              required
            >
              <option value="">Select a status</option>
              {statusOptions.map(option => (
                <option key={option} value={option}>{option}</option>
              ))}
            </select>
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Conductor <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              value={newReviewDate.conductor}
              onChange={(e) => setNewReviewDate(prev => ({ ...prev, conductor: e.target.value }))}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">Notes</label>
            <textarea
              value={newReviewDate.notes}
              onChange={(e) => setNewReviewDate(prev => ({ ...prev, notes: e.target.value }))}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500"
              rows="3"
            ></textarea>
          </div>
          <div className="flex justify-end">
            <button
              type="submit"
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              {editingReviewDate !== null ? "Update" : "Save"}
            </button>
          </div>
        </form>
      </Lightbox>
    </div>
  );
};

const PerformanceImprovementPlan = React.memo(({ caseData, caseId, companyId, onUpdate, currentUser, users }) => {
  const { workflows, defaultWorkflows, fetchWorkflows, fetchDefaultWorkflows, updateCaseWorkflow, startWorkflow, approveWorkflowStep } = useWorkflow();
  const [localCaseData, setLocalCaseData] = useState(caseData);
  const [saved, setSaved] = useState(false);
  const [error, setError] = useState(null);
  const [editingField, setEditingField] = useState(null);
  const pdfRef = useRef(null);

  const memoizedWorkflows = useMemo(() => workflows, [workflows]);
  const memoizedDefaultWorkflows = useMemo(() => defaultWorkflows, [defaultWorkflows]);

  useEffect(() => {
    if (memoizedWorkflows.length === 0) {
      fetchWorkflows();
    }
    if (Object.keys(memoizedDefaultWorkflows).length === 0) {
      fetchDefaultWorkflows();
    }
  }, [fetchWorkflows, fetchDefaultWorkflows, memoizedWorkflows, memoizedDefaultWorkflows]);

  const handleChangeWorkflow = useCallback(async (newWorkflowId) => {
    try {
      const updatedData = await updateCaseWorkflow(companyId, caseId, newWorkflowId);
      setLocalCaseData(prev => ({
        ...prev,
        ...updatedData
      }));
    } catch (error) {
      // Handle error (e.g., show an error message to the user)
    }
  }, [companyId, caseId, updateCaseWorkflow]);

  const autoSave = useCallback(
    debounce(async (data) => {
      console.log('AutoSave called with data:', data);
      if (!caseId) {
        console.error('No caseId available for autosave');
        return;
      }
      console.log('Attempting to update document with caseId:', caseId);
      const caseRef = doc(db, 'companies', companyId, 'cases', caseId);
      
      const filteredData = Object.fromEntries(
        Object.entries(data).filter(([_, v]) => v !== undefined)
      );

      try {
        console.log('Attempting to update Firebase with:', JSON.stringify(filteredData));
        await setDoc(caseRef, filteredData, { merge: true });
        console.log('AutoSave successful');
        
        // Verify the update
        const updatedDoc = await getDoc(caseRef);
        if (updatedDoc.exists()) {
          console.log('Updated document data:', updatedDoc.data());
        } else {
          console.error('Document does not exist after update');
        }
        
        setSaved(true);
        setTimeout(() => setSaved(false), 2000);
      } catch (error) {
        console.error("Error updating case document:", error);
        console.error("Error code:", error.code);
        console.error("Error message:", error.message);
        if (error.code === 'not-found') {
          console.error("Document not found. Attempting to create a new document.");
          try {
            await setDoc(caseRef, filteredData);
            console.log('New document created successfully');
          } catch (setDocError) {
            console.error("Error creating new document:", setDocError);
          }
        } else if (error.code === 'permission-denied') {
          console.error("Permission denied. Check Firebase security rules.");
        }
        setError("Failed to save case data. Please try again.");
      }
    }, 1000),
    [caseId, companyId]
  );

  useEffect(() => {
    return () => {
      autoSave.cancel();
    };
  }, [autoSave]);

  const handleInputChange = useCallback((name, value) => {
    setLocalCaseData(prev => {
      const updatedData = { ...prev, [name]: value };
      autoSave(updatedData);
      return updatedData;
    });
  }, [autoSave]);

  const handleInvolvedPartiesUpdate = useCallback((newInvolvedParties) => {
    console.log('Updating involved parties:', newInvolvedParties);
    setLocalCaseData(prev => {
      const updatedData = { ...prev, involvedParties: newInvolvedParties };
      autoSave(updatedData);
      return updatedData;
    });
  }, [autoSave]);

  const handleEventsUpdate = useCallback((newEvents) => {
    console.log('Updating events:', newEvents);
    setLocalCaseData(prev => {
      const updatedData = { ...prev, events: newEvents };
      autoSave(updatedData);
      return updatedData;
    });
  }, [autoSave]);

  const handlePrintCase = useReactToPrint({
    content: () => {
      const printElement = document.createElement('div');
      ReactDOM.render(<PDFView caseData={localCaseData} />, printElement);
      return printElement;
    },
    removeAfterPrint: true,
  });

  const PDFView = React.forwardRef(({ caseData }, ref) => (
    <div ref={ref} className="space-y-6 p-8">
      <h1 className="text-3xl font-bold">Performance Improvement Plan</h1>
      
      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">Case Information</h2>
        <table className="min-w-full divide-y divide-gray-200">
          <tbody className="bg-white divide-y divide-gray-200">
            <tr>
              <td className="px-6 py-4 whitespace-nowrap font-medium">Employee:</td>
              <td className="px-6 py-4 whitespace-nowrap">{caseData.employee}</td>
            </tr>
            <tr>
              <td className="px-6 py-4 whitespace-nowrap font-medium">Case Type:</td>
              <td className="px-6 py-4 whitespace-nowrap">Performance Improvement Plan</td>
            </tr>
            <tr>
              <td className="px-6 py-4 whitespace-nowrap font-medium">Assigned To:</td>
              <td className="px-6 py-4 whitespace-nowrap">{caseData.assignedToName}</td>
            </tr>
          </tbody>
        </table>
      </section>

      {/* Add more sections for Areas of Concern, Performance Improvement Goals, etc. */}
    </div>
  ));

  const handleReviewDatesUpdate = useCallback((updatedReviewDates) => {
    console.log('Updating review dates:', updatedReviewDates);
    setLocalCaseData(prev => {
      const updatedData = { ...prev, reviewDates: updatedReviewDates };
      console.log('Updated local case data:', updatedData);
      console.log('Calling autoSave with:', updatedData);
      autoSave(updatedData);
      return updatedData;
    });
  }, [autoSave]);

  const fetchCaseData = useCallback(async (companyId, caseId) => {
    try {
      const caseRef = doc(db, 'companies', companyId, 'cases', caseId);
      const caseSnap = await getDoc(caseRef);
      
      if (caseSnap.exists()) {
        const data = caseSnap.data();
        return {
          ...data,
          currentApprover: data.currentApprover || null // Ensure currentApprover is included
        };
      } else {
        console.error('No such case document!');
        return null;
      }
    } catch (error) {
      console.error('Error fetching case data:', error);
      throw error;
    }
  }, [db]);

  const handleStartWorkflow = useCallback(async () => {
    try {
      console.log('Starting workflow with users:', users);
      if (!users || users.length === 0) {
        console.error('Users data is not available');
        return;
      }

      console.log('Starting workflow with:', {
        companyId,
        caseId,
        workflow: localCaseData.workflow,
        users
      });
      const result = await startWorkflow(companyId, caseId, "Performance Improvement Plan", localCaseData.workflow, users);
      if (result.success) {
        console.log(result.message);
        const updatedCaseData = await fetchCaseData(companyId, caseId);
        if (updatedCaseData) {
          setLocalCaseData(updatedCaseData);
        }
      }
    } catch (error) {
      console.error('Error starting workflow:', error);
      setError('Failed to start workflow. Please try again.');
    }
  }, [companyId, caseId, localCaseData.workflow, users, startWorkflow, fetchCaseData]);

  const handleApproveStep = useCallback(async () => {
    try {
      const result = await approveWorkflowStep(companyId, caseId, localCaseData.currentApprovalStep, currentUser.uid);
      if (result.success) {
        const updatedCaseData = await fetchCaseData(companyId, caseId);
        setLocalCaseData(updatedCaseData);
      }
    } catch (error) {
      console.error('Error approving workflow step:', error);
      setError('Failed to approve workflow step. Please try again.');
    }
  }, [companyId, caseId, localCaseData.currentApprovalStep, currentUser.uid, approveWorkflowStep, fetchCaseData]);

  useEffect(() => {
    console.log('localCaseData updated:', localCaseData);
  }, [localCaseData]);

  console.log('PerformanceImprovementPlan render:', { 
    workflow: localCaseData.workflow, 
    currentStep: localCaseData.currentApprovalStep,
    approvals: localCaseData.approvals
  });

  const handleFilesUpdate = useCallback((newFiles) => {
    setLocalCaseData(prev => {
      const updatedData = { ...prev, files: newFiles };
      autoSave(updatedData);
      return updatedData;
    });
  }, [autoSave]);

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="mb-6 flex justify-between items-center">
        <div className="flex space-x-2">
    
          <Menu as="div" className="relative inline-block text-left">
            <Menu.Button className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
              Options
              <ChevronDown className="w-5 h-5 ml-2 -mr-1" aria-hidden="true" />
            </Menu.Button>
            <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={handlePrintCase}
                      className={`${
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                      } group flex items-center px-4 py-2 text-sm w-full text-left`}
                    >
                      <Printer className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                      Print Case
                    </button>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Menu>
        </div>
      </div>

      {error && (
        <div className="mb-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
          <strong className="font-bold">Error!</strong>
          <span className="block sm:inline"> {error}</span>
        </div>
      )}

      {saved && (
        <div className="mb-4 bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative" role="alert">
          <strong className="font-bold">Saved!</strong>
          <span className="block sm:inline"> Your changes have been saved.</span>
        </div>
      )}

      <div className="space-y-6">
        <EditableSection
          title="Areas of Concern"
          fieldName="areasOfConcern"
          value={localCaseData.areasOfConcern}
          onEdit={handleInputChange}
          icon={<AlertCircle className="w-5 h-5 sm:w-6 sm:h-6 text-red-500" />}
          isEditing={editingField === 'areasOfConcern'}
          setIsEditing={(isEditing) => setEditingField(isEditing ? 'areasOfConcern' : null)}
          onClose={() => setEditingField(null)}
          placeholder="Describe the areas of concern here..."
        />

        <EditableSection
          title="Performance Improvement Goals"
          fieldName="performanceImprovementGoals"
          value={localCaseData.performanceImprovementGoals}
          onEdit={handleInputChange}
          icon={<Target className="w-5 h-5 sm:w-6 sm:h-6 text-blue-500" />}
          isEditing={editingField === 'performanceImprovementGoals'}
          setIsEditing={(isEditing) => setEditingField(isEditing ? 'performanceImprovementGoals' : null)}
          onClose={() => setEditingField(null)}
          placeholder="Describe the performance improvement goals here..."
        />

        <EditableSection
          title="Expected Outcomes"
          fieldName="expectedOutcomes"
          value={localCaseData.expectedOutcomes}
          onEdit={handleInputChange}
          icon={<TrendingUp className="w-5 h-5 sm:w-6 sm:h-6 text-green-500" />}
          isEditing={editingField === 'expectedOutcomes'}
          setIsEditing={(isEditing) => setEditingField(isEditing ? 'expectedOutcomes' : null)}
          onClose={() => setEditingField(null)}
          placeholder="Describe the expected outcomes here..."
        />

        <EditableSection
          title="Support and Resources"
          fieldName="supportAndResources"
          value={localCaseData.supportAndResources}
          onEdit={handleInputChange}
          icon={<HelpCircle className="w-5 h-5 sm:w-6 sm:h-6 text-purple-500" />}
          isEditing={editingField === 'supportAndResources'}
          setIsEditing={(isEditing) => setEditingField(isEditing ? 'supportAndResources' : null)}
          onClose={() => setEditingField(null)}
          placeholder="Describe the support and resources here..."
        />

        <ReviewDatesSection
          reviewDates={localCaseData.reviewDates || []}
          onUpdate={handleReviewDatesUpdate}
        />

        <InvolvedPartiesSection
          involvedParties={localCaseData.involvedParties}
          companyId={companyId}
          onUpdate={handleInvolvedPartiesUpdate}
        />

<ReviewWorkflowSection
  workflow={localCaseData.workflow}
  currentStep={localCaseData.currentApprovalStep}
  caseType="Performance Improvement Plan"
  onStartWorkflow={handleStartWorkflow}
  onApproveStep={handleApproveStep}
  approvals={localCaseData.approvals || []}
  currentUser={currentUser}
  currentApprover={localCaseData.currentApprover}
  onChangeWorkflow={handleChangeWorkflow}
  availableWorkflows={memoizedWorkflows}
  users={users}
/>

        <EventTrackerSection
          events={localCaseData.events}
          onUpdate={handleEventsUpdate}
        />

        <FileUploadSection
          files={localCaseData.files}
          onUpdate={handleFilesUpdate}
          companyId={companyId}
          caseId={caseId}
        />

        {error && (
          <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4" role="alert">
            <p className="font-bold">Error</p>
            <p>{error}</p>
          </div>
        )}
      </div>
    </div>
  );
});

const EditableSection = React.memo(({ title, fieldName, value, onEdit, icon, isEditing, setIsEditing, onClose, placeholder }) => (
  <div className="bg-white shadow rounded-lg p-4 sm:p-6">
    <div className="flex justify-between items-center mb-4">
      <h3 className="text-lg font-medium text-gray-900 flex items-center">
        {icon}
        <span className="ml-2">{title}</span>
      </h3>
      <button
        onClick={() => setIsEditing(true)}
        className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        <Edit className="h-4 w-4 mr-1" />
        Edit
      </button>
    </div>
    <div className="bg-gray-50 rounded-lg p-4">
      {value ? (
        <div className="prose max-w-none text-sm text-gray-700" dangerouslySetInnerHTML={{ __html: value }} />
      ) : (
        <div className="text-center">
          <div className="flex items-center justify-center text-indigo-400">
            {React.cloneElement(icon, { className: "w-5 h-5 mr-2" })}
            <h3 className="text-sm font-medium text-gray-900">Add {title}</h3>
          </div>
          <p className="text-xs text-gray-500 mt-1">
            Click 'Edit' to provide details for {title.toLowerCase()}.
          </p>
        </div>
      )}
    </div>
    {isEditing && (
      <LightboxEditor
        isOpen={isEditing}
        onClose={() => {
          setIsEditing(false);
          onClose();
        }}
        title={title}
        value={value}
        onEdit={onEdit}
        fieldName={fieldName}
        placeholder={placeholder}
      />
    )}
  </div>
));

const LightboxEditor = React.memo(({ isOpen, onClose, title, value, onEdit, fieldName, placeholder }) => {
  const [localValue, setLocalValue] = useState(value || '');

  useEffect(() => {
    setLocalValue(value || '');
  }, [value]);

  const handleClose = () => {
    if (localValue !== value) {
      onEdit(fieldName, localValue || '');
    }
    onClose();
  };

  const handleEditorChange = (content) => {
    setLocalValue(content);
  };

  return (
    <Lightbox isOpen={isOpen} onClose={handleClose} title={title} maxWidth="max-w-4xl">
      <div className="relative">
        <button
          onClick={handleClose}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
          aria-label="Close"
        >
          <X className="w-6 h-6" />
        </button>
        <Editor
          apiKey="s6m55belr5vylvppptz7d16wyge9vizqs1qgptzxxbmy629m"
          value={localValue}
          init={{
            height: 400,
            menubar: false,
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount'
            ],
            toolbar:
              'undo redo | formatselect | bold italic backcolor | \
              alignleft aligncenter alignright alignjustify | \
              bullist numlist outdent indent | removeformat | help'
          }}
          onEditorChange={handleEditorChange}
        />
      </div>
      <div className="mt-4 flex justify-end">
        <button
          onClick={handleClose}
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Save & Close
        </button>
      </div>
    </Lightbox>
  );
});

export default PerformanceImprovementPlan;