import { Shield, Lock, Eye, FileText, UserPlus, UserCog, Trash, Settings, Workflow, GitBranch, RefreshCw, Plus, Users } from 'lucide-react';

export const roleIcons = {
  superuser: Shield,
  admin: Shield,
  manager: Lock,
  user: Eye
};

export const permissionIcons = {
  viewCases: Eye,
  createCases: FileText,
  editCases: UserCog,
  deleteCases: Trash,
  addUsers: UserPlus,
  editUsers: UserCog,
  editCompanySettings: Settings,
  viewWorkflows: Workflow,
  createWorkflows: GitBranch,
  editWorkflows: Workflow,
  deleteWorkflows: Trash,
  addNewWorkflows: Plus,
  changeWorkflowOnCase: RefreshCw,
  viewEmployees: Eye,
  addEmployees: UserPlus,
  editEmployees: UserCog
};

export const permissionLabels = {
  viewCases: 'View Cases',
  createCases: 'Create Cases',
  editCases: 'Edit Cases',
  deleteCases: 'Delete Cases',
  addUsers: 'Add Users',
  editUsers: 'Edit Users',
  editCompanySettings: 'Edit Company Settings',
  viewWorkflows: 'View Workflows',
  createWorkflows: 'Create Workflows',
  editWorkflows: 'Edit Workflows',
  deleteWorkflows: 'Delete Workflows',
  addNewWorkflows: 'Add New Workflows',
  changeWorkflowOnCase: 'Change Case Workflow',
  viewEmployees: 'View Employees',
  addEmployees: 'Add Employees',
  editEmployees: 'Edit Employees'
};

export const rolePermissions = {
  superuser: {
    viewCases: true,
    createCases: true,
    editCases: true,
    deleteCases: true,
    addUsers: true,
    editUsers: true,
    editCompanySettings: true,
    addNewWorkflows: true,
    changeWorkflowOnCase: true,
    viewWorkflows: true,
    createWorkflows: true,
    editWorkflows: true,
    deleteWorkflows: true,
    viewEmployees: true,
    addEmployees: true,
    editEmployees: true
  },
  admin: {
    viewCases: true,
    createCases: true,
    editCases: true,
    deleteCases: true,
    addUsers: true,
    editUsers: true,
    editCompanySettings: true,
    addNewWorkflows: true,
    changeWorkflowOnCase: true,
    viewWorkflows: true,
    createWorkflows: true,
    editWorkflows: true,
    deleteWorkflows: true,
    viewEmployees: true,
    addEmployees: true,
    editEmployees: true
  },
  manager: {
    viewCases: true,
    createCases: true,
    editCases: true,
    deleteCases: false,
    addUsers: false,
    editUsers: false,
    editCompanySettings: false,
    addNewWorkflows: false,
    changeWorkflowOnCase: true,
    viewWorkflows: true,
    createWorkflows: false,
    editWorkflows: false,
    deleteWorkflows: false,
    viewEmployees: true,
    addEmployees: false,
    editEmployees: true
  },
  user: {
    viewCases: true,
    createCases: false,
    editCases: false,
    deleteCases: false,
    addUsers: false,
    editUsers: false,
    editCompanySettings: false,
    addNewWorkflows: false,
    changeWorkflowOnCase: false,
    viewWorkflows: false,
    createWorkflows: false,
    editWorkflows: false,
    deleteWorkflows: false,
    viewEmployees: false,
    addEmployees: false,
    editEmployees: false
  }
};