import React, { createContext, useContext, useState, useEffect, useRef } from 'react';
import { useAuth } from './AuthContext';
import { useAppContext } from './AppContext';
import { collection, query, where, onSnapshot, orderBy, doc, updateDoc } from 'firebase/firestore';
import { db } from './firebase';

const NotificationContext = createContext();

export const useNotifications = () => useContext(NotificationContext);

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const { user } = useAuth();
  const { currentCompany } = useAppContext();

  const markNotificationAsRead = async (notificationId) => {
    try {
      // Update local state
      setNotifications(prevNotifications =>
        prevNotifications.map(notification =>
          notification.id === notificationId
            ? { ...notification, read: true }
            : notification
        )
      );

      // Update on the server
      if (user && currentCompany) {
        const notificationRef = doc(db, 'companies', currentCompany.id, 'notifications', notificationId);
        await updateDoc(notificationRef, {
          read: true
        });
        console.log('Notification marked as read on the server');
      } else {
        console.error('User or company not available, could not update server');
      }
    } catch (error) {
      console.error('Error marking notification as read:', error);
      // Optionally, revert the local state change if the server update fails
      // setNotifications(prevNotifications => [...prevNotifications]);
    }
  };

  const listenerRef = useRef(null);

  useEffect(() => {
    if (listenerRef.current) return;
    listenerRef.current = setupNotificationListener(user, currentCompany, setNotifications);
    return () => {
      if (listenerRef.current) {
        listenerRef.current();
        listenerRef.current = null;
      }
    };
  }, [user, currentCompany]);

  return (
    <NotificationContext.Provider value={{ notifications, markNotificationAsRead }}>
      {children}
    </NotificationContext.Provider>
  );
};

const setupNotificationListener = (user, currentCompany, setNotifications) => {
  if (user && currentCompany) {
    const notificationsRef = collection(db, 'companies', currentCompany.id, 'notifications');
    const q = query(
      notificationsRef,
      where("userId", "==", user.uid),
      orderBy("createdAt", "desc")
    );

    console.log('NotificationProvider: Setting up listener');
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const newNotifications = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setNotifications(newNotifications);
      console.log('NotificationProvider: New notifications', newNotifications.length);
    });

    return unsubscribe;
  }
  return () => {};
};