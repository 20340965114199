import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, query, getDocs, doc, getDoc, where, writeBatch, deleteDoc, documentId } from 'firebase/firestore';
import { format, parseISO } from 'date-fns';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

import { db } from './firebase';
import { useAppContext } from './AppContext';
import { useAuth } from './AuthContext';

import { 
  ArrowUpDown, 
  FileText, 
  Users, 
  Calendar, 
  Tag, 
  CheckCircle, 
  User, 
  Briefcase, 
  Settings, 
  Mail, 
  CheckSquare, 
  Square,
  Filter, 
  Download, 
  FileSpreadsheet, 
  File,
  Plus,
  X,
  ClipboardList
} from 'lucide-react';

const CaseOverviewPage = () => {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { currentCompany } = useAppContext();
  const { user } = useAuth();
  const [filters, setFilters] = useState({0: {field: '', value: ''}});
  const [openFilter, setOpenFilter] = useState(null);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');
  const reportRef = useRef();
  const [viewingOwnCases, setViewingOwnCases] = useState(true);

  const columns = useMemo(() => [
    { id: 'referenceId', name: 'Ref ID', icon: <FileText className="h-5 w-5 text-gray-500" />, sortable: true },
    { id: 'employeeName', name: 'Employee', icon: <Users className="h-5 w-5 text-gray-500" />, sortable: true },
    { id: 'type', name: 'Type', icon: <Tag className="h-5 w-5 text-gray-500" />, sortable: true },
    { id: 'status', name: 'Status', icon: <CheckCircle className="h-5 w-5 text-gray-500" />, sortable: true },
    { id: 'assignedStaff', name: 'Assigned To', icon: <User className="h-5 w-5 text-gray-500" />, sortable: true },
  ], []);

  const checkBelongsToCompany = useCallback((companyId) => {
    if (!user || !user.uid) return false;
    return user.uid === companyId || user.companyId === companyId;
  }, [user]);

  const formatCreatedAt = useCallback((createdAt) => {
    if (!createdAt) return 'N/A';
    
    let dateValue;
    if (typeof createdAt === 'string') {
      dateValue = parseISO(createdAt);
    } else if (createdAt instanceof Date) {
      dateValue = createdAt;
    } else if (createdAt.toDate && typeof createdAt.toDate === 'function') {
      dateValue = createdAt.toDate();
    } else {
      console.log('Unexpected createdAt format:', createdAt);
      return 'N/A';
    }
    return format(dateValue, 'MMM d, yyyy');
  }, []);

  const fetchCases = useCallback(async () => {
    if (!currentCompany || !user || !user.uid) return;

    const cacheKey = `cases_${currentCompany.id}_${user.uid}`;
    const cachedData = localStorage.getItem(cacheKey);
    const cacheTimestamp = localStorage.getItem(`${cacheKey}_timestamp`);
    const isValidCache = cacheTimestamp && (Date.now() - parseInt(cacheTimestamp, 10)) < 3600000;

    if (cachedData && isValidCache) {
      setData(JSON.parse(cachedData));
      setLoading(false);
      return;
    }

    try {
      setLoading(true);
      const casesRef = collection(db, 'companies', currentCompany.id, 'cases');
      const querySnapshot = await getDocs(query(casesRef));
      const casesData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      const employeeIds = new Set(casesData.map(caseData => caseData.employee).filter(Boolean));
      const userIds = new Set(casesData.map(caseData => caseData.assignedTo).filter(Boolean));

      const [employeeData, userData] = await Promise.all([
        fetchBatchData('companies', currentCompany.id, 'employees', Array.from(employeeIds)),
        fetchBatchData('users', null, null, Array.from(userIds))
      ]);

      const processedCases = casesData.map(caseData => ({
        ...caseData,
        employeeName: employeeData[caseData.employee] 
          ? `${employeeData[caseData.employee].firstName} ${employeeData[caseData.employee].lastName}` 
          : 'N/A',
        assignedStaff: userData[caseData.assignedTo] 
          ? `${userData[caseData.assignedTo].firstName} ${userData[caseData.assignedTo].lastName}` 
          : 'Unknown',
        createdOn: formatCreatedAt(caseData.createdAt)
      }));

      setData(processedCases);
      localStorage.setItem(cacheKey, JSON.stringify(processedCases));
      localStorage.setItem(`${cacheKey}_timestamp`, Date.now().toString());
    } catch (error) {
      console.error("Error fetching cases:", error);
      setError("Failed to fetch cases: " + error.message);
    } finally {
      setLoading(false);
    }
  }, [currentCompany, user, formatCreatedAt]);

  // Helper function to fetch data in batches
  const fetchBatchData = async (collectionName, companyId, subCollectionName, ids) => {
    console.log(`Fetching batch data for ${collectionName}`, { companyId, subCollectionName, ids });
    const batches = [];
    for (let i = 0; i < ids.length; i += 10) {
      const batch = ids.slice(i, i + 10);
      let ref;
      if (subCollectionName) {
        ref = collection(db, collectionName, companyId, subCollectionName);
      } else {
        ref = collection(db, collectionName);
      }
      const q = query(ref, where(documentId(), 'in', batch));
      console.log(`Executing batch query for ${collectionName}`, { batchSize: batch.length, query: q });
      const querySnapshot = await getDocs(q);
      console.log(`Batch query executed for ${collectionName}`, { resultsCount: querySnapshot.size });
      batches.push(querySnapshot);
    }
    return batches.flatMap(b => b.docs).reduce((acc, doc) => {
      acc[doc.id] = doc.data();
      return acc;
    }, {});
  };

  useEffect(() => {
    if (currentCompany && user && user.uid) {
      fetchCases();
    } else {
      setLoading(true);
    }
  }, [currentCompany, user, fetchCases]);

  const filteredAndSortedData = useMemo(() => {
    if (!data || !user) return [];

    return data
      .filter(row => {
        const filtersPass = Object.values(filters).every(({field, value}) => {
          if (!field || !value) return true;
          const rowValue = row[field];
          return rowValue && String(rowValue).toLowerCase().includes(String(value).toLowerCase());
        });
        return filtersPass && (!viewingOwnCases || row.assignedTo === user.uid);
      })
      .sort((a, b) => {
        if (!sortColumn) return 0;
        const aValue = a[sortColumn];
        const bValue = b[sortColumn];
        if (aValue === bValue) return 0;
        if (aValue == null) return 1;
        if (bValue == null) return -1;
        return aValue < bValue ? (sortDirection === 'asc' ? -1 : 1) : (sortDirection === 'asc' ? 1 : -1);
      });
  }, [data, filters, sortColumn, sortDirection, viewingOwnCases, user]);

  const handleRowClick = (caseId) => {
    navigate(`/cases/${caseId}`);
  };

  const renderMobileCards = () => {
    return filteredAndSortedData.map((row) => (
      <div key={row.id} className="bg-white shadow rounded-lg p-4 mb-4" onClick={() => handleRowClick(row.id)}>
        {columns.map(column => (
          <div key={column.id} className="flex items-center justify-between py-2 border-b border-gray-200 last:border-b-0">
            <div className="flex items-center">
              {column.icon}
              <span className="ml-2 font-medium">{column.name}</span>
            </div>
            <div className="text-gray-600">
              {column.id === 'status' ? (
                <span className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusColor(row[column.id])}`}>
                  {row[column.id]}
                </span>
              ) : (
                row[column.id]
              )}
            </div>
          </div>
        ))}
      </div>
    ));
  };

  useEffect(() => {
    if (!user) {
      setViewingOwnCases(false);
    }
  }, [user]);

  const handleDeleteCase = async (caseId) => {
    if (!currentCompany || !user) return;

    try {
      const caseRef = doc(db, 'companies', currentCompany.id, 'cases', caseId);
      await deleteDoc(caseRef);
      alert('Case deleted successfully');

      // Clear cache
      const cacheKey = `cases_${currentCompany.id}_${user.uid}`;
      localStorage.removeItem(cacheKey);
      localStorage.removeItem(`${cacheKey}_timestamp`);

      // Refetch cases to update the UI
      fetchCases();
    } catch (error) {
      console.error("Error deleting case:", error);
      setError("Failed to delete case");
    }
  };

  const getStatusColor = useCallback((status) => {
    switch (status.toLowerCase()) {
      case 'open':
        return 'bg-green-100 text-green-800';
      case 'closed':
        return 'bg-red-100 text-red-800';
      case 'in progress':
        return 'bg-yellow-100 text-yellow-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  }, []);

  const handleSort = useCallback((columnId) => {
    if (sortColumn === columnId) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(columnId);
      setSortDirection('asc');
    }
  }, [sortColumn, sortDirection]);

  if (loading) {
    return <div className="flex justify-center items-center h-screen">
      <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
    </div>;
  }

  if (!currentCompany || !user) {
    return <div className="text-center mt-8">Loading user and company data...</div>;
  }

  if (error) {
    return <div className="text-center mt-8 text-red-600">Error: {error}</div>;
  }

  if (!data) {
    return <div className="text-center mt-8">No data available. Please try refreshing the page.</div>;
  }

  if (data.length === 0) {
    return <div className="text-center mt-8">No cases found for the current filters.</div>;
  }

  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8 max-w-7xl" ref={reportRef}>
      <div className="bg-gradient-to-r from-blue-500 to-indigo-600 p-4 mb-6 rounded-lg shadow-lg">
        <div className="flex justify-between items-center">
          <h1 className="text-2xl font-bold text-white flex items-center">
            <ClipboardList className="mr-2" size={24} />
            Case Overview
          </h1>
        </div>
      </div>

      <div className="bg-white rounded-lg overflow-hidden shadow">
        <div className="p-6">
          <div className="flex flex-col sm:flex-row justify-between mb-4">
            <div className="flex flex-wrap space-y-2 sm:space-y-0 sm:space-x-2">
             

              <div className="relative inline-block text-left">
                <button
                  onClick={() => setOpenFilter(openFilter === 'sort' ? null : 'sort')}
                  className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  <ArrowUpDown className="h-5 w-5 mr-2 text-indigo-500" />
                  Sort
                </button>
                {openFilter === 'sort' && (
                  <div className="origin-top-left absolute left-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
                    {columns.filter(col => col.sortable).map((column) => (
                      <button
                        key={column.id}
                        className={`${sortColumn === column.id && sortDirection === 'asc' ? 'bg-gray-100 text-gray-900' : 'text-gray-700'} group flex items-center px-4 py-2 text-sm w-full`}
                        onClick={() => handleSort(column.id)}
                      >
                        {column.icon}
                        <span className="ml-2">{column.name}</span>
                        {sortColumn === column.id && (
                          <ArrowUpDown className="ml-auto h-5 w-5 text-indigo-500" />
                        )}
                      </button>
                    ))}
                  </div>
                )}
              </div>

              {user ? (
                <button
                  onClick={() => setViewingOwnCases(!viewingOwnCases)}
                  className={`inline-flex items-center px-4 py-2 border rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${
                    viewingOwnCases
                      ? 'bg-indigo-600 text-white hover:bg-indigo-700'
                      : 'bg-white text-gray-700 hover:bg-gray-50'
                  }`}
                >
                  <User className="h-5 w-5 mr-2" />
                  {viewingOwnCases ? 'View All Cases' : 'View My Cases'}
                </button>
              ) : (
                <button
                  disabled
                  className="inline-flex items-center px-4 py-2 border rounded-md shadow-sm text-sm font-medium bg-gray-200 text-gray-400 cursor-not-allowed"
                >
                  <User className="h-5 w-5 mr-2" />
                  View My Cases
                </button>
              )}
            </div>
          </div>

          {/* Mobile view */}
          <div className="sm:hidden">
            {renderMobileCards()}
          </div>

          {/* Desktop view */}
          <div className="hidden sm:block overflow-x-auto custom-scrollbar">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  {columns.map((column) => (
                    <th key={column.id} className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                      <div className="flex items-center">
                        {column.icon}
                        <span className="ml-1">{column.name}</span>
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {filteredAndSortedData.map((row) => (
                  <tr 
                    key={row.id} 
                    className="hover:bg-gray-50 cursor-pointer transition duration-150 ease-in-out"
                    onClick={() => handleRowClick(row.id)}
                  >
                    {columns.map((column) => (
                      <td key={column.id} className="px-3 py-2 whitespace-nowrap text-sm text-gray-500">
                        {column.id === 'status' ? (
                          <span className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusColor(row[column.id])}`}>
                            {row[column.id]}
                          </span>
                        ) : (
                          row[column.id]
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <style>{`
        .custom-scrollbar {
          scrollbar-width: thin;
          scrollbar-color: #9CA3AF #F3F4F6;
        }
        .custom-scrollbar::-webkit-scrollbar {
          width: 8px;
          height: 8px;
        }
        .custom-scrollbar::-webkit-scrollbar-track {
          background: #F3F4F6;
          border-radius: 10px;
        }
        .custom-scrollbar::-webkit-scrollbar-thumb {
          background-color: #9CA3AF;
          border-radius: 10px;
          border: 2px solid #F3F4F6;
        }
        .custom-scrollbar::-webkit-scrollbar-thumb:hover {
          background-color: #6B7280;
        }
      `}</style>
  
    </div>
  );
};

export default React.memo(CaseOverviewPage);