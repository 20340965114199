import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, addDoc, query, where, getDocs, orderBy, limit } from 'firebase/firestore';
import { db } from './firebase';
import { useAppContext } from './AppContext';
import { useAuth } from './AuthContext';
import { FolderPlus, User, FileText, UserCheck, Workflow, Edit2 } from 'lucide-react';
import EmployeeDropdown from './components/EmployeeDropdown';
import AssignedToDropdown from './components/AssignedToDropdown';
import { useWorkflow } from './contexts/WorkflowContext';
import { usePermissions } from './contexts/PermissionsContext';

const pageName = 'Add Case';

const AddCasePage = () => {
  const navigate = useNavigate();
  const { currentCompany } = useAppContext();
  const { user } = useAuth();
  const [caseData, setCaseData] = useState({
    referenceId: '',
    employee: '',
    type: '',
    assignedTo: '',
    status: 'Open',
  });
  const [users, setUsers] = useState([]);
  const [workflow, setWorkflow] = useState(null);
  const [isEditingWorkflow, setIsEditingWorkflow] = useState(false);
  const { workflows, defaultWorkflows, fetchWorkflows, fetchDefaultWorkflows } = useWorkflow();
  const { permissions } = usePermissions();

  // Add this console log
  console.log("Current permissions:", permissions);

  const fetchUsers = useCallback(async () => {
    if (!currentCompany) return;
    try {
      const usersRef = collection(db, 'users');
      const q = query(usersRef, where('companyId', '==', currentCompany.id));
      const querySnapshot = await getDocs(q);
      const usersData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setUsers(usersData);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  }, [currentCompany]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  useEffect(() => {
    generateReferenceId();
  }, [currentCompany]);

  useEffect(() => {
    if (currentCompany) {
      fetchWorkflows();
      fetchDefaultWorkflows();
    }
  }, [currentCompany, fetchWorkflows, fetchDefaultWorkflows]);

  const generateReferenceId = async () => {
    if (!currentCompany) return;

    const currentYear = new Date().getFullYear();
    const companyPrefix = currentCompany.abbreviation || currentCompany.name.substring(0, 3).toUpperCase();

    const casesRef = collection(db, 'companies', currentCompany.id, 'cases');
    const q = query(
      casesRef,
      where('referenceId', '>=', `${companyPrefix}-${currentYear}-`),
      where('referenceId', '<', `${companyPrefix}-${currentYear + 1}-`),
      orderBy('referenceId', 'desc'),
      limit(1)
    );

    const querySnapshot = await getDocs(q);
    let nextNumber = 1;

    if (!querySnapshot.empty) {
      const lastCase = querySnapshot.docs[0].data();
      const lastNumber = parseInt(lastCase.referenceId.split('-')[2]);
      nextNumber = lastNumber + 1;
    }

    const paddedNumber = nextNumber.toString().padStart(4, '0');
    const newReferenceId = `${companyPrefix}-${currentYear}-${paddedNumber}`;

    setCaseData(prev => ({ ...prev, referenceId: newReferenceId }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCaseData(prev => ({ ...prev, [name]: value }));

    if (name === 'type' && defaultWorkflows[value]) {
      const defaultWorkflow = workflows.find(w => w.id === defaultWorkflows[value]);
      setWorkflow(defaultWorkflow);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!currentCompany) {
      alert('No company selected. Unable to add case.');
      return;
    }

    if (!workflow) {
      alert('Please select an approval workflow.');
      return;
    }

    try {
      const newCase = {
        ...caseData,
        companyId: currentCompany.id,
        createdBy: user.uid,
        createdAt: new Date().toISOString(),
        lastUpdated: new Date().toISOString(),
        status: 'Open',
        workflow: workflow
      };
      const casesRef = collection(db, 'companies', currentCompany.id, 'cases');
      const docRef = await addDoc(casesRef, newCase);
      alert(`Case added successfully! Reference ID: ${caseData.referenceId}`);

      // Clear cache
      const cacheKey = `cases_${currentCompany.id}_${user.uid}`;
      localStorage.removeItem(cacheKey);

      navigate(`/cases/${docRef.id}`);
    } catch (error) {
      console.error('Error adding case:', error);
      alert('Failed to add case. Please try again.');
    }
  };

  useEffect(() => {
    document.title = `${pageName} - HR Case Hub`;
  }, []);

  const handleEditWorkflow = () => {
    setIsEditingWorkflow(true);
  };

  const handleWorkflowChange = (e) => {
    const selectedWorkflowId = e.target.value;
    const selectedWorkflow = workflows.find(w => w.id === selectedWorkflowId);
    setWorkflow(selectedWorkflow);
    setIsEditingWorkflow(false);
  };

  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8 max-w-7xl">
      <div className="bg-gradient-to-r from-blue-500 to-indigo-600 p-4 mb-6 rounded-lg shadow-lg">
        <h1 className="text-2xl font-bold text-white flex items-center">
          <FolderPlus className="mr-2" size={24} />
          Add New Case
        </h1>
      </div>

      <div className="bg-white shadow-md rounded-lg p-8">
        <div className="max-w-4xl mx-auto">
          <form onSubmit={handleSubmit} className="space-y-6">
            <InputField
              label="Reference ID"
              id="referenceId"
              name="referenceId"
              value={caseData.referenceId}
              readOnly
              icon={<FileText className="text-gray-400" size={20} />}
            />
            <div>
              <label htmlFor="employee" className="block text-sm font-medium text-gray-700 mb-1">
                Employee <span className="text-red-500">*</span>
              </label>
              <div className="relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <User className="text-gray-400" size={20} />
                </div>
                <EmployeeDropdown
                  value={caseData.employee}
                  onChange={handleInputChange}
                  companyId={currentCompany?.id}
                  required
                  name="employee"
                />
              </div>
            </div>
            <SelectField
              label="Case Type"
              id="type"
              name="type"
              value={caseData.type}
              onChange={handleInputChange}
              required
              options={[
                { value: "Performance Improvement Plan", label: "Performance Improvement Plan" },
                { value: "Investigation", label: "Investigation" },
                { value: "Corrective Action", label: "Corrective Action" }
              ]}
              icon={<FileText className="text-gray-400" size={20} />}
            />
            <div>
              <label htmlFor="assignedTo" className="block text-sm font-medium text-gray-700 mb-1">
                Assigned To <span className="text-red-500">*</span>
              </label>
              <div className="relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <UserCheck className="text-gray-400" size={20} />
                </div>
                <AssignedToDropdown
                  value={caseData.assignedTo}
                  onChange={handleInputChange}
                  users={users}
                  required
                  name="assignedTo"
                />
              </div>
            </div>
            <WorkflowField
              workflow={workflow}
              isEditing={isEditingWorkflow}
              onEdit={handleEditWorkflow}
              onChange={handleWorkflowChange}
              canEdit={permissions.changeWorkflowOnCase}
              workflows={workflows}
              required={true}
            />
            <button
              type="submit"
              className="w-full bg-blue-600 hover:bg-blue-700 text-white font-semibold py-3 px-4 rounded-md transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 flex items-center justify-center"
            >
              <FolderPlus className="mr-2" size={20} />
              Add Case
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

const InputField = ({ label, id, name, value, onChange, readOnly, icon }) => (
  <div>
    <label htmlFor={id} className="block text-sm font-medium text-gray-700 mb-1">
      {label}
    </label>
    <div className="relative rounded-md shadow-sm">
      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        {icon}
      </div>
      <input
        type="text"
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        readOnly={readOnly}
        className={`w-full pl-10 border border-gray-300 rounded-md shadow-sm p-2 ${readOnly ? 'bg-gray-100' : 'focus:ring-blue-500 focus:border-blue-500'} transition duration-300 ease-in-out`}
      />
    </div>
  </div>
);

const SelectField = ({ label, id, name, value, onChange, required, options, icon }) => (
  <div>
    <label htmlFor={id} className="block text-sm font-medium text-gray-700 mb-1">
      {label} {required && <span className="text-red-500">*</span>}
    </label>
    <div className="relative rounded-md shadow-sm">
      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        {icon}
      </div>
      <select
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        required={required}
        className="w-full pl-10 border border-gray-300 rounded-md shadow-sm p-2 focus:ring-blue-500 focus:border-blue-500 transition duration-300 ease-in-out"
      >
        <option value="">Select {label}</option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  </div>
);

const WorkflowField = ({ workflow, isEditing, onEdit, onChange, canEdit, workflows, required }) => {
  // Add this console log
  console.log("WorkflowField canEdit:", canEdit);

  return (
    <div className="mb-4">
      <label htmlFor="workflow" className="block text-sm font-medium text-gray-700 mb-1">
        Approval Workflow {required && <span className="text-red-500">*</span>}
      </label>
      <div className="relative rounded-md shadow-sm">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <Workflow className="text-gray-400" size={20} />
        </div>
        {isEditing ? (
          <select
            id="workflow"
            name="workflow"
            value={workflow ? workflow.id : ''}
            onChange={onChange}
            className="w-full pl-10 border border-gray-300 rounded-md shadow-sm p-2 focus:ring-blue-500 focus:border-blue-500"
            required={required}
          >
            <option value="">Select a workflow</option>
            {workflows.map(w => (
              <option key={w.id} value={w.id}>{w.name}</option>
            ))}
          </select>
        ) : (
          <input
            type="text"
            id="workflow"
            name="workflow"
            value={workflow ? workflow.name : 'No workflow selected'}
            readOnly
            className="w-full pl-10 border border-gray-300 rounded-md shadow-sm p-2 bg-gray-100"
          />
        )}
        {canEdit && !isEditing && (
          <button
            type="button"
            onClick={onEdit}
            className="absolute inset-y-0 right-0 pr-3 flex items-center text-blue-500 hover:text-blue-600"
          >
            <Edit2 size={20} />
          </button>
        )}
      </div>
    </div>
  );
};

export default AddCasePage;