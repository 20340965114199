import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, addDoc } from 'firebase/firestore';
import { db } from './firebase';
import { useAppContext } from './AppContext';
import { UserPlus, User, Mail, Briefcase, Building, Calendar, Users, Globe, Hash, HeartPulse, Flag, Heart, AlertTriangle } from 'lucide-react';
import { usePermissions } from './contexts/PermissionsContext';

const AddEmployeePage = () => {
  const navigate = useNavigate();
  const { currentCompany } = useAppContext();
  const { checkPermission } = usePermissions();
  const [employeeData, setEmployeeData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    position: '',
    department: '',
    hireDate: '',
    gender: '',
    ethnicity: '',
    dateOfBirth: '',
    disabilityStatus: '',
    veteranStatus: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEmployeeData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!currentCompany) {
      alert('No company selected. Unable to add employee.');
      return;
    }
    if (!checkPermission('addEmployees')) {
      return <div>You don't have permission to add employees.</div>;
    }
    try {
      const employeesRef = collection(db, 'companies', currentCompany.id, 'employees');
      const docRef = await addDoc(employeesRef, {
        ...employeeData,
        createdAt: new Date().toISOString()
      });
      alert('Employee added successfully!');
      navigate(`/employees/${docRef.id}`);
    } catch (error) {
      console.error('Error adding employee:', error);
      alert('Failed to add employee. Please try again.');
    }
  };

  const pageName = 'Add Employee';

  useEffect(() => {
    document.title = `${pageName} - HR Case Hub`;
  }, []);

  return (
    <div className="max-w-4xl mx-auto">
      <h2 className="text-3xl font-bold mb-6 text-gray-800 flex items-center">
        <UserPlus className="mr-2" size={32} />
        Add New Employee
      </h2>
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <InputField
            label="First Name"
            id="firstName"
            name="firstName"
            value={employeeData.firstName}
            onChange={handleInputChange}
            required
            icon={<User className="text-gray-400" size={20} />}
          />
          <InputField
            label="Last Name"
            id="lastName"
            name="lastName"
            value={employeeData.lastName}
            onChange={handleInputChange}
            required
            icon={<User className="text-gray-400" size={20} />}
          />
          <InputField
            label="Email"
            id="email"
            name="email"
            type="email"
            value={employeeData.email}
            onChange={handleInputChange}
            required
            icon={<Mail className="text-gray-400" size={20} />}
          />
          <InputField
            label="Position"
            id="position"
            name="position"
            value={employeeData.position}
            onChange={handleInputChange}
            required
            icon={<Briefcase className="text-gray-400" size={20} />}
          />
          <InputField
            label="Department"
            id="department"
            name="department"
            value={employeeData.department}
            onChange={handleInputChange}
            required
            icon={<Building className="text-gray-400" size={20} />}
          />
          <InputField
            label="Hire Date"
            id="hireDate"
            name="hireDate"
            type="date"
            value={employeeData.hireDate}
            onChange={handleInputChange}
            required
            icon={<Calendar className="text-gray-400" size={20} />}
          />
          <SelectField
            label="Gender"
            id="gender"
            name="gender"
            value={employeeData.gender}
            onChange={handleInputChange}
            required
            options={['Male', 'Female', 'Non-Binary', 'Prefer not to say']}
            icon={<Users className="text-gray-400" size={20} />}
          />
          <SelectField
            label="Ethnicity"
            id="ethnicity"
            name="ethnicity"
            value={employeeData.ethnicity}
            onChange={handleInputChange}
            required
            options={[
              'African American', 'Asian', 'Caucasian', 'Hispanic', 'Native American',
              'Pacific Islander', 'Two or More Races', 'Other', 'Prefer not to say'
            ]}
            icon={<Globe className="text-gray-400" size={20} />}
          />
          <InputField
            label="Date of Birth"
            id="dateOfBirth"
            name="dateOfBirth"
            type="date"
            value={employeeData.dateOfBirth}
            onChange={handleInputChange}
            required
            icon={<Calendar className="text-gray-400" size={20} />}
          />
          <SelectField
            label="Disability Status"
            id="disabilityStatus"
            name="disabilityStatus"
            value={employeeData.disabilityStatus}
            onChange={handleInputChange}
            required
            options={['No disability', 'Has disability', 'Unknown', 'Prefer not to say']}
            icon={<HeartPulse className="text-gray-400" size={20} />}
          />
          <SelectField
  label="Sexual Orientation"
  id="sexualOrientation"
  name="sexualOrientation"
  value={employeeData.sexualOrientation}
  onChange={handleInputChange}
  required
  options={['Heterosexual', 'Homosexual', 'Bisexual', 'Other', 'Prefer not to say']}
  icon={<Heart className="text-gray-400" size={20} />}
/>
<SelectField
  label="Risk Level"
  id="riskLevel"
  name="riskLevel"
  value={employeeData.riskLevel}
  onChange={handleInputChange}
  required
  options={['Low', 'Medium', 'High']}
  icon={<AlertTriangle className="text-gray-400" size={20} />}
/>
          <SelectField
            label="Veteran Status"
            id="veteranStatus"
            name="veteranStatus"
            value={employeeData.veteranStatus}
            onChange={handleInputChange}
            required
            options={['Veteran', 'Non-veteran', 'Unknown', 'Prefer not to say']}
            icon={<Flag className="text-gray-400" size={20} />}
          />
        </div>
        <button
          type="submit"
          className="w-full bg-blue-600 hover:bg-blue-700 text-white font-semibold py-3 px-4 rounded-md transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 flex items-center justify-center"
        >
          <UserPlus className="mr-2" size={20} />
          Add Employee
        </button>
      </form>
    </div>
  );
};

const InputField = ({ label, id, name, type = "text", value, onChange, required, icon }) => (
  <div>
    <label htmlFor={id} className="block text-sm font-medium text-gray-700 mb-1">
      {label} {required && <span className="text-red-500">*</span>}
    </label>
    <div className="relative rounded-md shadow-sm">
      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        {icon}
      </div>
      <input
        type={type}
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        required={required}
        className="w-full pl-10 border border-gray-300 rounded-md shadow-sm p-2 focus:ring-blue-500 focus:border-blue-500 transition duration-300 ease-in-out"
      />
    </div>
  </div>
);

const SelectField = ({ label, id, name, value, onChange, required, options, icon }) => (
  <div>
    <label htmlFor={id} className="block text-sm font-medium text-gray-700 mb-1">
      {label} {required && <span className="text-red-500">*</span>}
    </label>
    <div className="relative rounded-md shadow-sm">
      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        {icon}
      </div>
      <select
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        required={required}
        className="w-full pl-10 border border-gray-300 rounded-md shadow-sm p-2 focus:ring-blue-500 focus:border-blue-500 transition duration-300 ease-in-out"
      >
        <option value="">Select {label}</option>
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  </div>
);

export default AddEmployeePage;
