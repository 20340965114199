import React, { createContext, useState, useContext, useEffect, useCallback, useMemo, useReducer, useRef } from 'react';
import { db, firestore } from '../firebase';
import { collection, doc, getDoc, getDocs, addDoc, query, where, updateDoc, deleteDoc, runTransaction, setDoc, serverTimestamp } from 'firebase/firestore';
import { useAppContext } from '../AppContext';
import { usePermissions } from './PermissionsContext';
import { getFunctions, httpsCallable } from 'firebase/functions';

export const WorkflowContext = createContext();

const workflowReducer = (state, action) => {
  switch (action.type) {
    case 'SET_WORKFLOWS':
      return { ...state, workflows: action.payload, isLoading: false };
    case 'SET_DEFAULT_WORKFLOWS':
      return { ...state, defaultWorkflows: action.payload, isLoading: false };
    case 'SET_ERROR':
      return { ...state, error: action.payload, isLoading: false };
    case 'SET_LOADING':
      return { ...state, isLoading: action.payload };
    default:
      return state;
  }
};

export const WorkflowProvider = ({ children }) => {
  const [state, dispatch] = useReducer(workflowReducer, {
    workflows: [],
    defaultWorkflows: {},
    error: null,
    isLoading: false
  });

  const fetchingRef = useRef(false);

  const { currentUser, currentCompany, isInitialLoadComplete } = useAppContext();
  const { permissions, loading: permissionsLoading } = usePermissions();

  const setDefaultWorkflows = useCallback((defaultWorkflows) => {
    dispatch({ type: 'SET_DEFAULT_WORKFLOWS', payload: defaultWorkflows });
  }, []);

  const fetchWorkflows = useCallback(async () => {
    if (!currentCompany || state.isLoading || state.workflows.length > 0) {
      console.log('Skipping workflow fetch');
      return;
    }

    dispatch({ type: 'SET_LOADING', payload: true });
    try {
      console.log('Fetching workflows for company:', currentCompany.id);
      const workflowsRef = collection(db, 'companies', currentCompany.id, 'workflows');
      const snapshot = await getDocs(workflowsRef);
      const fetchedWorkflows = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      console.log('Fetched workflows:', fetchedWorkflows);
      dispatch({ type: 'SET_WORKFLOWS', payload: fetchedWorkflows });
    } catch (error) {
      console.error("Error fetching workflows:", error);
      dispatch({ type: 'SET_ERROR', payload: "Failed to fetch workflows. Please check your permissions." });
    } finally {
      dispatch({ type: 'SET_LOADING', payload: false });
    }
  }, [currentCompany, state.isLoading, state.workflows.length]);

  const fetchDefaultWorkflows = useCallback(async () => {
    if (!currentCompany || state.isLoading || Object.keys(state.defaultWorkflows).length > 0) {
      console.log('Skipping default workflow fetch');
      return;
    }

    dispatch({ type: 'SET_LOADING', payload: true });
    try {
      console.log('Fetching default workflows for company:', currentCompany.id);
      const companyRef = doc(db, 'companies', currentCompany.id);
      const companyDoc = await getDoc(companyRef);
      if (companyDoc.exists()) {
        const companyData = companyDoc.data();
        const fetchedDefaultWorkflows = companyData.defaultWorkflows || {};
        console.log('Fetched default workflows:', fetchedDefaultWorkflows);
        dispatch({ type: 'SET_DEFAULT_WORKFLOWS', payload: fetchedDefaultWorkflows });
      } else {
        console.log('No company document found, returning empty default workflows');
        dispatch({ type: 'SET_DEFAULT_WORKFLOWS', payload: {} });
      }
    } catch (error) {
      console.error('Error fetching default workflows:', error);
      dispatch({ type: 'SET_ERROR', payload: "Failed to fetch default workflows." });
    } finally {
      dispatch({ type: 'SET_LOADING', payload: false });
    }
  }, [currentCompany, state.isLoading, state.defaultWorkflows]);

  const createWorkflow = useCallback(async (newWorkflow) => {
    if (!currentCompany) return;
    try {
      const workflowsRef = collection(db, 'companies', currentCompany.id, 'workflows');
      const newWorkflowRef = await addDoc(workflowsRef, newWorkflow);
      const createdWorkflow = { id: newWorkflowRef.id, ...newWorkflow };
      dispatch({ type: 'SET_WORKFLOWS', payload: [...state.workflows, createdWorkflow] });
    } catch (error) {
      console.error("Error creating workflow:", error);
      dispatch({ type: 'SET_ERROR', payload: "Failed to create workflow." });
    }
  }, [currentCompany, state.workflows]);

  const deleteWorkflow = useCallback(async (workflowId) => {
    if (!currentCompany) return;
    try {
      const workflowRef = doc(db, 'companies', currentCompany.id, 'workflows', workflowId);
      await deleteDoc(workflowRef);
      dispatch({ type: 'SET_WORKFLOWS', payload: state.workflows.filter(w => w.id !== workflowId) });
    } catch (error) {
      console.error("Error deleting workflow:", error);
      dispatch({ type: 'SET_ERROR', payload: "Failed to delete workflow." });
    }
  }, [currentCompany, state.workflows]);

  const updateWorkflow = useCallback(async (workflowId, updatedWorkflow) => {
    if (!currentCompany) return;
    try {
      const workflowRef = doc(db, 'companies', currentCompany.id, 'workflows', workflowId);
      await updateDoc(workflowRef, updatedWorkflow);
      dispatch({ type: 'SET_WORKFLOWS', payload: state.workflows.map(w => w.id === workflowId ? { ...w, ...updatedWorkflow } : w) });
    } catch (error) {
      console.error("Error updating workflow:", error);
      dispatch({ type: 'SET_ERROR', payload: "Failed to update workflow." });
    }
  }, [currentCompany, state.workflows]);

  const setDefaultWorkflow = useCallback(async (caseType, workflowId) => {
    if (!currentCompany) return;
    try {
      const companyRef = doc(db, 'companies', currentCompany.id);
      await updateDoc(companyRef, {
        [`defaultWorkflows.${caseType}`]: workflowId
      });
      const updatedDefaultWorkflows = {
        ...state.defaultWorkflows[currentCompany.id],
        [caseType]: workflowId
      };
      dispatch({ type: 'SET_DEFAULT_WORKFLOWS', payload: { [currentCompany.id]: updatedDefaultWorkflows } });
    } catch (error) {
      console.error("Error setting default workflow:", error);
      dispatch({ type: 'SET_ERROR', payload: "Failed to set default workflow." });
    }
  }, [currentCompany, state.defaultWorkflows]);

  const updateCaseWorkflow = useCallback(async (companyId, caseId, newWorkflowId) => {
    if (!companyId || !caseId || !newWorkflowId) {
      console.error('Missing required parameters for updateCaseWorkflow');
      return;
    }
    const caseRef = doc(db, 'companies', companyId, 'cases', caseId);
    try {
      const newWorkflow = state.workflows.find(w => w.id === newWorkflowId);
      if (!newWorkflow) {
        throw new Error('Workflow not found');
      }
      const updatedData = {
        workflow: newWorkflow,
        currentApprovalStep: -1,
        approvals: []
      };
      await updateDoc(caseRef, updatedData);
      console.log('Case workflow updated successfully');
      return updatedData;
    } catch (error) {
      console.error("Error updating case workflow:", error);
      dispatch({ type: 'SET_ERROR', payload: "Failed to update case workflow. Please try again." });
      throw error;
    }
  }, [state.workflows]);

  const startWorkflow = useCallback(async (companyId, caseId, caseType, workflow, users) => {
    try {
      if (!workflow || !workflow.steps || workflow.steps.length === 0) {
        console.error('Workflow or workflow steps are undefined');
        return;
      }

      const firstStep = workflow.steps[0];
      if (!firstStep || !firstStep.approver) {
        console.error('First step or approver is undefined');
        return;
      }

      const approverData = users.find(user => user.id === firstStep.approver);
      if (!approverData) {
        console.error('Approver not found in users array');
        return;
      }

      // Update the case document in Firebase
      const caseRef = doc(db, 'companies', companyId, 'cases', caseId);
      await updateDoc(caseRef, {
        workflow: workflow,
        currentApprovalStep: 0,
        currentApprover: firstStep.approver,
        workflowStartedAt: serverTimestamp(),
        status: 'In Progress'
      });

      const sendWorkflowEmail = httpsCallable(getFunctions(), 'sendWorkflowEmail');
      const result = await sendWorkflowEmail({
        approverEmail: approverData.email,
        caseId,
        caseType,
        approverName: `${approverData.firstName} ${approverData.lastName}`
      });
      console.log('Workflow start email result:', result.data);

      // Add this block to create a notification
      const notificationsRef = collection(db, 'companies', companyId, 'notifications');
      await addDoc(notificationsRef, {
        userId: firstStep.approver,
        caseId: caseId,
        message: `You have a new ${caseType} case to approve`,
        createdAt: serverTimestamp(),
        read: false
      });

      return { success: true, message: 'Workflow started successfully' };
    } catch (error) {
      console.error("Error starting workflow:", error);
      dispatch({ type: 'SET_ERROR', payload: "Failed to start workflow. Please try again." });
      throw error;
    }
  }, [db]);

  const approveWorkflowStep = useCallback(async (companyId, caseId, currentStep, userId) => {
    if (!companyId || !caseId || currentStep === undefined || !userId) {
      console.error('Missing required parameters for approveWorkflowStep');
      return;
    }
    const caseRef = doc(db, 'companies', companyId, 'cases', caseId);
    try {
      await runTransaction(db, async (transaction) => {
        const caseDoc = await transaction.get(caseRef);
        if (!caseDoc.exists()) {
          throw new Error("Case document does not exist!");
        }
        const caseData = caseDoc.data();
        const workflow = caseData.workflow;
        if (currentStep !== caseData.currentApprovalStep) {
          throw new Error("Current step mismatch");
        }
        const newApproval = {
          step: currentStep,
          approvedBy: userId,
          approvedAt: serverTimestamp()
        };
        const newApprovals = [...(caseData.approvals || []), newApproval];
        const nextStep = currentStep + 1;
        const updates = {
          approvals: newApprovals,
          currentApprovalStep: nextStep < workflow.steps.length ? nextStep : -2, // -2 indicates workflow completion
          currentApprover: nextStep < workflow.steps.length ? workflow.steps[nextStep].approver : null,
          status: nextStep < workflow.steps.length ? 'In Progress' : 'Completed'
        };
        transaction.update(caseRef, updates);

        // Add this block to create a notification for the next approver
        if (nextStep < workflow.steps.length) {
          const notificationsRef = collection(db, 'companies', companyId, 'notifications');
          await addDoc(notificationsRef, {
            userId: workflow.steps[nextStep].approver,
            caseId: caseId,
            message: `You have a case waiting for your approval`,
            createdAt: serverTimestamp(),
            read: false
          });
        }
      });
      console.log('Workflow step approved successfully');
      return { success: true, message: 'Workflow step approved successfully' };
    } catch (error) {
      console.error("Error approving workflow step:", error);
      dispatch({ type: 'SET_ERROR', payload: "Failed to approve workflow step. Please try again." });
      throw error;
    }
  }, [db]);

  useEffect(() => {
    if (isInitialLoadComplete && currentUser && currentCompany && !permissionsLoading && permissions?.viewWorkflows) {
      fetchWorkflows();
      fetchDefaultWorkflows();
    }
  }, [isInitialLoadComplete, currentUser, currentCompany, permissionsLoading, permissions, fetchWorkflows, fetchDefaultWorkflows]);

  const value = useMemo(() => ({
    workflows: state.workflows,
    defaultWorkflows: state.defaultWorkflows,
    error: state.error,
    isLoading: state.isLoading,
    fetchWorkflows,
    fetchDefaultWorkflows,
    createWorkflow,
    deleteWorkflow,
    updateWorkflow,
    setDefaultWorkflow,
    setDefaultWorkflows,
    updateCaseWorkflow,
    startWorkflow,
    approveWorkflowStep
  }), [
    state.workflows,
    state.defaultWorkflows,
    state.error,
    state.isLoading,
    fetchWorkflows,
    fetchDefaultWorkflows,
    createWorkflow,
    deleteWorkflow,
    updateWorkflow,
    setDefaultWorkflow,
    setDefaultWorkflows,
    updateCaseWorkflow,
    startWorkflow,
    approveWorkflowStep
  ]);

  return (
    <WorkflowContext.Provider value={value}>
      {children}
    </WorkflowContext.Provider>
  );
};

export const useWorkflow = () => useContext(WorkflowContext);