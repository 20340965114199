import React, { useState, useEffect } from 'react';
import { Plus, Trash2, ArrowRight, Workflow } from 'lucide-react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebase';

const LightboxForm = ({ isOpen, onClose, onSubmit, currentCompany, editingWorkflow }) => {
  const [workflowName, setWorkflowName] = useState(editingWorkflow ? editingWorkflow.name : '');
  const [steps, setSteps] = useState(editingWorkflow ? editingWorkflow.steps : [{ approver: '' }]);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      if (currentCompany) {
        const usersRef = collection(db, 'users');
        const q = query(usersRef, where('companyId', '==', currentCompany.id));
        const querySnapshot = await getDocs(q);
        const usersData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          name: doc.data().name || `${doc.data().firstName} ${doc.data().lastName}`
        }));
        setUsers(usersData);
      }
    };
    fetchUsers();
  }, [currentCompany]);

  useEffect(() => {
    if (editingWorkflow) {
      setWorkflowName(editingWorkflow.name);
      setSteps(editingWorkflow.steps);
    } else {
      setWorkflowName('');
      setSteps([{ approver: '' }]);
    }
  }, [editingWorkflow]);

  const handleAddStep = () => {
    setSteps([...steps, { approver: '' }]);
  };

  const handleRemoveStep = (index) => {
    const newSteps = steps.filter((_, i) => i !== index);
    setSteps(newSteps);
  };

  const handleStepChange = (index, value) => {
    const newSteps = [...steps];
    newSteps[index].approver = value;
    setSteps(newSteps);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const workflowData = { name: workflowName, steps };
    if (editingWorkflow) {
      workflowData.id = editingWorkflow.id;
    }
    onSubmit(workflowData);
    setWorkflowName('');
    setSteps([{ approver: '' }]);
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
      <div className="relative top-20 mx-auto p-5 border w-2/3 max-w-2xl shadow-lg rounded-md bg-white">
        <div className="mt-3">
          <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4 flex items-center">
            <Workflow className="mr-2" size={24} />
            {editingWorkflow ? 'Edit Workflow' : 'Create New Workflow'}
          </h3>
          <form onSubmit={handleSubmit} className="mt-2">
            <input
              type="text"
              value={workflowName}
              onChange={(e) => setWorkflowName(e.target.value)}
              placeholder="Workflow Name"
              className="w-full p-2 border rounded mb-4"
              required
            />
            <div className="mb-4 p-4 bg-blue-50 rounded-lg">
              <h4 className="text-md font-semibold text-blue-700 mb-2">Approval Process</h4>
              <div className="space-y-2">
                {steps.map((step, index) => (
                  <div key={index} className="flex items-center space-x-2">
                    <span className="text-sm font-medium text-gray-600">Step {index + 1}</span>
                    <select
                      value={step.approver}
                      onChange={(e) => handleStepChange(index, e.target.value)}
                      className="flex-grow p-2 border rounded"
                      required
                    >
                      <option value="">Select Approver</option>
                      {users.map((user) => (
                        <option key={user.id} value={user.id}>
                          {user.name}
                        </option>
                      ))}
                    </select>
                    {index > 0 && (
                      <button
                        type="button"
                        onClick={() => handleRemoveStep(index)}
                        className="p-1 bg-red-500 text-white rounded hover:bg-red-600 transition-colors"
                      >
                        <Trash2 size={16} />
                      </button>
                    )}
                  </div>
                ))}
              </div>
              <button
                type="button"
                onClick={handleAddStep}
                className="mt-2 px-4 py-2 bg-green-500 text-white rounded flex items-center hover:bg-green-600 transition-colors"
              >
                <Plus size={16} className="mr-2" />
                Add Step
              </button>
            </div>
            <div className="text-sm text-gray-600 mb-4">
              <div className="font-semibold mb-1">Approval Flow:</div>
              <div className="flex items-center">
                {steps.map((_, index) => (
                  <React.Fragment key={index}>
                    <span className="bg-blue-100 text-blue-800 px-2 py-1 rounded">Step {index + 1}</span>
                    {index < steps.length - 1 && <ArrowRight className="mx-2" size={16} />}
                  </React.Fragment>
                ))}
                <ArrowRight className="mx-2" size={16} />
                <span className="bg-green-100 text-green-800 px-2 py-1 rounded">Approved</span>
              </div>
            </div>
            <div className="flex justify-end">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 bg-gray-300 text-gray-800 rounded mr-2 hover:bg-gray-400 transition-colors"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
              >
                {editingWorkflow ? 'Update Workflow' : 'Create Workflow'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LightboxForm;