import React, { createContext, useContext, useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from './firebase';
import { useAuth } from './AuthContext';

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const { user: authUser, loading: authLoading } = useAuth();
  const [currentCompany, setCurrentCompany] = useState(null);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isInitialized, setIsInitialized] = useState(false);

  const fetchCompanyDataRef = useRef({});

  const fetchCompanyData = useCallback(async (companyId) => {
    if (fetchCompanyDataRef.current[companyId]) {
      return fetchCompanyDataRef.current[companyId];
    }

    console.log('Fetching company data for ID:', companyId);
    
    const cachedCompanyData = localStorage.getItem(`companyData_${companyId}`);
    const cacheTimestamp = localStorage.getItem(`companyData_${companyId}_timestamp`);
    const isValidCache = cacheTimestamp && (Date.now() - parseInt(cacheTimestamp, 10)) < 3600000; // 1 hour cache

    if (cachedCompanyData && isValidCache) {
      console.log('Using cached company data');
      return JSON.parse(cachedCompanyData);
    }

    try {
      const companyDoc = await getDoc(doc(db, 'companies', companyId));
      if (companyDoc.exists()) {
        const companyData = { id: companyId, ...companyDoc.data() };
        localStorage.setItem(`companyData_${companyId}`, JSON.stringify(companyData));
        localStorage.setItem(`companyData_${companyId}_timestamp`, Date.now().toString());
        fetchCompanyDataRef.current[companyId] = companyData;
        return companyData;
      } else {
        console.error('Company document does not exist');
        return null;
      }
    } catch (error) {
      console.error('Error fetching company data:', error);
      return null;
    }
  }, []);

  const fetchUserData = useCallback(async () => {
    try {
      const userDoc = await getDoc(doc(db, 'users', authUser.uid));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        const fullUserData = { ...authUser, ...userData };
        setUser(fullUserData);

        if (userData.companyId) {
          const [companyData] = await Promise.all([
            fetchCompanyData(userData.companyId),
            setUser(fullUserData)
          ]);

          if (companyData) {
            setCurrentCompany(companyData);
          }
        }
      } else {
        setUser(authUser);
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      setError('Failed to load user data. Please try again.');
    } finally {
      setLoading(false);
      setIsInitialized(true);
    }
  }, [authUser, fetchCompanyData]);

  const hasRunEffect = useRef(false);

  useEffect(() => {
    if (authLoading) {
      setLoading(true);
      return;
    }

    if (!authUser) {
      setUser(null);
      setLoading(false);
      setIsInitialized(true);
      return;
    }

    fetchUserData();
  }, [authUser, authLoading, fetchUserData]);

  const contextValue = useMemo(() => ({
    currentCompany, 
    setCurrentCompany, 
    user, 
    loading, 
    error,
    isInitialized
  }), [currentCompany, user, loading, error, isInitialized]);

  return (
    <AppContext.Provider value={contextValue}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);