import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc, getDocs, collection, query, where } from 'firebase/firestore';
import { db } from './firebase';
import { useAppContext } from './AppContext';
import { ChevronLeft, AlertCircle, Edit2, User, Briefcase, Clock, Calendar, Trash, Plus, FileText } from 'lucide-react';
import AssignedToDropdown from './components/AssignedToDropdown';
import EmployeeDropdown from './components/EmployeeDropdown';
import { PerformanceImprovementPlan, CorrectiveAction, Investigation } from './caseTypes';
import Lightbox from './shared/Lightbox';
import { usePermissions } from './contexts/PermissionsContext';

const pageName = 'Case Detail';

const CaseDetailPage = () => {
  const { caseId } = useParams();
  const navigate = useNavigate();
  const { user } = useAppContext();
  const { checkPermission } = usePermissions();
  const [caseData, setCaseData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editingAssigned, setEditingAssigned] = useState(false);
  const [editingEmployee, setEditingEmployee] = useState(false);
  const [editingDisposition, setEditingDisposition] = useState(false);
  const [editingStatus, setEditingStatus] = useState(false);
  const [users, setUsers] = useState([]);
  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    document.title = `${pageName} - HR Case Hub`;
  }, []);

  const fetchCaseData = useCallback(async () => {
    if (!caseId || !user.companyId) return;
    
    setLoading(true);
    setError(null);
    try {
      const caseRef = doc(db, 'companies', user.companyId, 'cases', caseId);
      const caseSnap = await getDoc(caseRef);
      
      if (caseSnap.exists()) {
        const data = { id: caseSnap.id, ...caseSnap.data() };
        
        // Fetch assigned user name
        if (data.assignedTo) {
          const userDoc = await getDoc(doc(db, 'users', data.assignedTo));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            data.assignedToName = `${userData.firstName} ${userData.lastName}`;
          }
        }

        // Fetch employee name
        if (data.employeeId) {
          const employeeDoc = await getDoc(doc(db, 'companies', user.companyId, 'employees', data.employeeId));
          if (employeeDoc.exists()) {
            const employeeData = employeeDoc.data();
            data.employeeName = `${employeeData.firstName} ${employeeData.lastName}`;
          } else {
            data.employeeName = 'Employee Not Found';
          }
        } else {
          data.employeeName = 'Employee Not Assigned';
        }
        
        setCaseData(data);
      } else {
        setError('Case not found');
      }
    } catch (err) {
      console.error("Error fetching case data:", err);
      setError('Failed to fetch case data');
    } finally {
      setLoading(false);
    }
  }, [caseId, user.companyId]);

  const fetchDropdownData = useCallback(async () => {
    if (!user.companyId) return;

    try {
      // Fetch users
      const usersRef = collection(db, 'users');
      const userQuery = query(usersRef, where('companyId', '==', user.companyId));
      const userSnapshot = await getDocs(userQuery);
      const userData = userSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setUsers(userData);
      console.log('Fetched users:', userData); // Add this log

      // Fetch employees
      const employeesRef = collection(db, 'companies', user.companyId, 'employees');
      const employeeSnapshot = await getDocs(employeesRef);
      const employeeData = employeeSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setEmployees(employeeData);
      console.log('Fetched employees:', employeeData); // Add this log
    } catch (error) {
      console.error("Error fetching dropdown data:", error);
      setError("Failed to fetch dropdown data");
    }
  }, [user.companyId]);

  useEffect(() => {
    fetchCaseData();
    fetchDropdownData();
  }, [fetchCaseData, fetchDropdownData]);

  const handleAssignedChange = async (e) => {
    const newAssignedId = e.target.value;
    try {
      const caseRef = doc(db, 'companies', user.companyId, 'cases', caseId);
      await updateDoc(caseRef, { assignedTo: newAssignedId });

      let newAssignedToName = '';
      if (newAssignedId) {
        const userDoc = await getDoc(doc(db, 'users', newAssignedId));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          newAssignedToName = `${userData.firstName} ${userData.lastName}`;
        }
      }

      setCaseData(prev => ({ ...prev, assignedTo: newAssignedId, assignedToName: newAssignedToName }));
      setEditingAssigned(false);

      // Clear cache
      const cacheKey = `cases_${user.companyId}_${user.uid}`;
      localStorage.removeItem(cacheKey);
    } catch (error) {
      console.error("Error updating assigned user:", error);
      setError("Failed to update assigned user");
    }
  };

  const handleEmployeeChange = async (e) => {
    const newEmployeeId = e.target.value;
    try {
      const caseRef = doc(db, 'companies', user.companyId, 'cases', caseId);
      await updateDoc(caseRef, { employeeId: newEmployeeId });

      let newEmployeeName = '';
      if (newEmployeeId) {
        const employeeDoc = await getDoc(doc(db, 'companies', user.companyId, 'employees', newEmployeeId));
        if (employeeDoc.exists()) {
          const employeeData = employeeDoc.data();
          newEmployeeName = `${employeeData.firstName} ${employeeData.lastName}`;
        }
      }

      setCaseData(prev => ({ ...prev, employeeId: newEmployeeId, employeeName: newEmployeeName }));
      setEditingEmployee(false);

      // Clear cache
      const cacheKey = `cases_${user.companyId}_${user.uid}`;
      localStorage.removeItem(cacheKey);
    } catch (error) {
      console.error("Error updating employee:", error);
      setError("Failed to update employee");
    }
  };

  const handleCaseUpdate = (updatedData) => {
    setCaseData(prevData => ({ ...prevData, ...updatedData }));
    
    // Clear cache
    const cacheKey = `cases_${user.companyId}_${user.uid}`;
    localStorage.removeItem(cacheKey);
  };

  const handleDispositionSave = async (newDisposition) => {
    try {
      const dispositionWithNames = await Promise.all(newDisposition.map(async (item) => {
        if (item.employeeId) {
          const employeeDoc = await getDoc(doc(db, 'companies', user.companyId, 'employees', item.employeeId));
          if (employeeDoc.exists()) {
            const employeeData = employeeDoc.data();
            return {
              ...item,
              employeeName: `${employeeData.firstName} ${employeeData.lastName}`
            };
          }
        }
        return item;
      }));

      const caseRef = doc(db, 'companies', user.companyId, 'cases', caseId);
      await updateDoc(caseRef, { disposition: dispositionWithNames });
      setCaseData(prev => ({ ...prev, disposition: dispositionWithNames }));
      setEditingDisposition(false);

      // Clear cache
      const cacheKey = `cases_${user.companyId}_${user.uid}`;
      localStorage.removeItem(cacheKey);
    } catch (error) {
      console.error("Error updating disposition:", error);
      setError("Failed to update disposition");
    }
  };

  const handleStatusChange = async (e) => {
    const newStatus = e.target.value;
    try {
      const caseRef = doc(db, 'companies', user.companyId, 'cases', caseId);
      await updateDoc(caseRef, { status: newStatus });
      setCaseData(prev => ({ ...prev, status: newStatus }));
      setEditingStatus(false);

      // Clear cache
      const cacheKey = `cases_${user.companyId}_${user.uid}`;
      localStorage.removeItem(cacheKey);
    } catch (error) {
      console.error("Error updating status:", error);
      setError("Failed to update status");
    }
  };

  const getOutcomeColor = (outcome) => {
    switch (outcome) {
      case 'Terminated':
        return 'bg-red-100 text-red-800';
      case 'Suspended':
        return 'bg-orange-100 text-orange-800';
      case 'Written Warning':
        return 'bg-yellow-100 text-yellow-800';
      case 'Verbal Warning':
        return 'bg-blue-100 text-blue-800';
      case 'No Action':
        return 'bg-green-100 text-green-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'Open':
        return 'bg-green-100 text-green-800';
      case 'In Progress':
        return 'bg-blue-100 text-blue-800';
      case 'Pending Review':
        return 'bg-yellow-100 text-yellow-800';
      case 'Closed':
        return 'bg-gray-100 text-gray-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const handleStatusSave = async () => {
    try {
      const caseRef = doc(db, 'companies', user.companyId, 'cases', caseId);
      await updateDoc(caseRef, { status: caseData.status });
      setEditingStatus(false);

      // Clear cache
      const cacheKey = `cases_${user.companyId}_${user.uid}`;
      localStorage.removeItem(cacheKey);
    } catch (error) {
      console.error("Error updating status:", error);
      setError("Failed to update status");
    }
  };

  const handleAssignedSave = async () => {
    try {
      const caseRef = doc(db, 'companies', user.companyId, 'cases', caseId);
      await updateDoc(caseRef, { assignedTo: caseData.assignedTo });
      const userDoc = await getDoc(doc(db, 'users', caseData.assignedTo));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setCaseData(prev => ({ ...prev, assignedToName: `${userData.firstName} ${userData.lastName}` }));
      }
      setEditingAssigned(false);

      // Clear cache
      const cacheKey = `cases_${user.companyId}_${user.uid}`;
      localStorage.removeItem(cacheKey);
    } catch (error) {
      console.error("Error updating assigned user:", error);
      setError("Failed to update assigned user");
    }
  };

  const handleEmployeeSave = async () => {
    try {
      const caseRef = doc(db, 'companies', user.companyId, 'cases', caseId);
      await updateDoc(caseRef, { employeeId: caseData.employeeId });
      const employeeDoc = await getDoc(doc(db, 'companies', user.companyId, 'employees', caseData.employeeId));
      if (employeeDoc.exists()) {
        const employeeData = employeeDoc.data();
        setCaseData(prev => ({ ...prev, employeeName: `${employeeData.firstName} ${employeeData.lastName}` }));
      }
      setEditingEmployee(false);

      // Clear cache
      const cacheKey = `cases_${user.companyId}_${user.uid}`;
      localStorage.removeItem(cacheKey);
    } catch (error) {
      console.error("Error updating employee:", error);
      setError("Failed to update employee");
    }
  };

  if (loading) {
    return <div className="flex justify-center items-center h-screen">Loading...</div>;
  }

  if (error) {
    return (
      <div className="p-6 bg-red-100 border border-red-400 text-red-700 rounded flex items-center">
        <AlertCircle className="mr-2" />
        {error}
      </div>
    );
  }

  if (!caseData) {
    return <div className="p-6">Case not found</div>;
  }

  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8 max-w-7xl">
      <button
        onClick={() => navigate('/cases')}
        className="flex items-center text-blue-500 hover:text-blue-600 mb-6"
      >
        <ChevronLeft className="h-5 w-5 mr-1" />
        Back to Cases
      </button>

      <div className="bg-white shadow-lg rounded-lg overflow-hidden mb-6">
        <div className="bg-gradient-to-r from-blue-500 to-indigo-600 p-6">
          <h1 className="text-3xl font-bold text-white mb-2">{caseData.type}</h1>
          <div className="flex items-center text-white">
            <User className="mr-2" />
            <span>{caseData.employeeName || "Employee Not Assigned"}</span>
            {caseData.referenceId && (
              <>
                <span className="mx-2">|</span>
                <FileText className="mr-1" size={16} />
                <span>Case Number: {caseData.referenceId}</span>
              </>
            )}
          </div>
        </div>
        <div className="p-4 sm:p-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
          <CaseInfoItem
            icon={<User className="text-blue-500" />}
            label="Assigned To"
            value={caseData.assignedToName || "Not Assigned"}
            isEditing={editingAssigned}
            onEdit={() => setEditingAssigned(true)}
            editComponent={
              <LightboxEditor
                isOpen={editingAssigned}
                onClose={() => setEditingAssigned(false)}
                title="Edit Assigned To"
                onSave={handleAssignedSave}
              >
                <AssignedToDropdown
                  value={caseData.assignedTo}
                  onChange={(e) => setCaseData(prev => ({ ...prev, assignedTo: e.target.value }))}
                  users={users}
                />
              </LightboxEditor>
            }
          />
          {checkPermission('editCase') && (
            <EditableField
              label="Status"
              value={caseData.status}
              onEdit={() => setEditingStatus(true)}
              editComponent={
                <LightboxEditor
                  isOpen={editingStatus}
                  onClose={() => setEditingStatus(false)}
                  title="Edit Status"
                  onSave={handleStatusSave}
                >
                  <select
                    value={caseData.status}
                    onChange={(e) => setCaseData(prev => ({ ...prev, status: e.target.value }))}
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  >
                    <option value="Open">Open</option>
                    <option value="In Progress">In Progress</option>
                    <option value="Pending Review">Pending Review</option>
                    <option value="Closed">Closed</option>
                  </select>
                </LightboxEditor>
              }
            />
          )}
          <CaseInfoItem
            icon={<Calendar className="text-green-500" />}
            label="Created On"
            value={
              caseData.createdAt
                ? caseData.createdAt instanceof Date
                  ? caseData.createdAt.toLocaleDateString()
                  : typeof caseData.createdAt.toDate === 'function'
                  ? caseData.createdAt.toDate().toLocaleDateString()
                  : new Date(caseData.createdAt).toLocaleDateString()
                : "Not available"
            }
          />
          <CaseInfoItem
            icon={<AlertCircle className="text-purple-500" />}
            label="Disposition"
            value={
              caseData.disposition && caseData.disposition.length > 0 ? (
                <div className="grid grid-cols-1 gap-2">
                  {caseData.disposition.map((item, index) => (
                    <div key={index} className="flex items-center space-x-2 bg-gray-50 p-2 rounded">
                      <span className={`font-semibold text-xs px-2 py-1 rounded-full ${getOutcomeColor(item.outcome)}`}>
                        {item.outcome}
                      </span>
                      <span className="text-gray-600">{item.employeeName || 'Unknown Employee'}</span>
                    </div>
                  ))}
                </div>
              ) : "Not set"
            }
            isEditing={editingDisposition}
            onEdit={() => setEditingDisposition(true)}
            editComponent={
              <DispositionLightbox
                isOpen={editingDisposition}
                onClose={() => setEditingDisposition(false)}
                disposition={caseData.disposition || []}
                onSave={handleDispositionSave}
                companyId={caseData.companyId}
              />
            }
          />
        </div>
      </div>

      <div className="bg-white shadow-lg rounded-lg overflow-hidden">
        <div className="p-6">
          {caseData.type === 'Performance Improvement Plan' && (
            <PerformanceImprovementPlan
              caseData={caseData}
              caseId={caseId}
              companyId={user.companyId}
              onUpdate={handleCaseUpdate}
              currentUser={user}
              users={users}
            />
          )}
          {caseData.type === 'Corrective Action' && (
            <CorrectiveAction
              caseData={caseData}
              caseId={caseId}
              companyId={user.companyId}
              onUpdate={handleCaseUpdate}
              currentUser={user}
              users={users}
            />
          )}
          {caseData.type === 'Investigation' && (
            <Investigation
              caseData={caseData}
              caseId={caseId}
              companyId={user.companyId}
              onUpdate={handleCaseUpdate}
              currentUser={user}
              users={users}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const CaseInfoItem = ({ icon, label, value, isEditing, onEdit, editComponent }) => (
  <div className="bg-white p-3 rounded-lg shadow-md transition-all duration-300 hover:shadow-lg">
    <div className="flex items-center justify-between mb-1">
      <div className="flex items-center">
        {icon}
        <label className="ml-2 text-xs font-medium text-gray-600">{label}</label>
      </div>
      {onEdit && (
        <button 
          onClick={onEdit}
          className="p-1 rounded-full bg-blue-100 text-blue-600 hover:bg-blue-200 transition-colors duration-200"
        >
          <Edit2 size={12} />
        </button>
      )}
    </div>
    {isEditing ? (
      editComponent
    ) : (
      <div className="text-sm text-gray-800 truncate">
        {typeof value === 'string' ? value : (
          <div className="space-y-1">
            {value}
          </div>
        )}
      </div>
    )}
  </div>
);

const DispositionLightbox = ({ isOpen, onClose, disposition, onSave, companyId }) => {
  const [localDisposition, setLocalDisposition] = useState(disposition.length > 0 ? disposition : [{ outcome: '', employeeId: '', employeeName: '' }]);
  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    const fetchEmployees = async () => {
      const employeesRef = collection(db, 'companies', companyId, 'employees');
      const employeesSnapshot = await getDocs(employeesRef);
      const employeesData = employeesSnapshot.docs.map(doc => ({
        id: doc.id,
        name: `${doc.data().firstName} ${doc.data().lastName}`
      }));
      setEmployees(employeesData);
    };

    fetchEmployees();
  }, [companyId]);

  const handleAdd = () => {
    setLocalDisposition([...localDisposition, { outcome: '', employeeId: '', employeeName: '' }]);
  };

  const handleChange = (index, field, value) => {
    const newDisposition = [...localDisposition];
    newDisposition[index][field] = value;
    if (field === 'employeeId') {
      const selectedEmployee = employees.find(emp => emp.id === value);
      newDisposition[index].employeeName = selectedEmployee ? selectedEmployee.name : '';
    }
    setLocalDisposition(newDisposition);
  };

  const handleDelete = (index) => {
    if (localDisposition.length > 1) {
      const newDisposition = localDisposition.filter((_, i) => i !== index);
      setLocalDisposition(newDisposition);
    }
  };

  const handleSave = () => {
    const filteredDisposition = localDisposition.filter(item => item.outcome && item.employeeId);
    onSave(filteredDisposition);
    onClose();
  };

  return (
    <Lightbox isOpen={isOpen} onClose={onClose} title="Edit Disposition" maxWidth="max-w-4xl">
      <div className="space-y-4">
        {localDisposition.map((item, index) => (
          <div key={index} className="flex items-center space-x-2">
            <select
              value={item.outcome}
              onChange={(e) => handleChange(index, 'outcome', e.target.value)}
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            >
              <option value="">Select Outcome</option>
              <option value="Terminated">Terminated</option>
              <option value="Suspended">Suspended</option>
              <option value="Written Warning">Written Warning</option>
              <option value="Verbal Warning">Verbal Warning</option>
              <option value="No Action">No Action</option>
            </select>
            <select
              value={item.employeeId}
              onChange={(e) => handleChange(index, 'employeeId', e.target.value)}
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            >
              <option value="">Select Employee</option>
              {employees.map((employee) => (
                <option key={employee.id} value={employee.id}>{employee.name}</option>
              ))}
            </select>
            <button 
              onClick={() => handleDelete(index)} 
              className="text-red-600 hover:text-red-800"
              disabled={localDisposition.length === 1}
            >
              <Trash className="w-5 h-5" />
            </button>
          </div>
        ))}
        <button
          onClick={handleAdd}
          className="mt-2 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
        >
          <Plus className="w-5 h-5 mr-2" />
          Add Another Disposition
        </button>
      </div>
      <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
        <button
          type="button"
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
          onClick={handleSave}
        >
          Save Dispositions
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
          onClick={onClose}
        >
          Cancel
        </button>
      </div>
    </Lightbox>
  );
};

const LightboxEditor = ({ isOpen, onClose, title, children, onSave }) => {
  return (
    <Lightbox isOpen={isOpen} onClose={onClose} title={title}>
      <div className="p-4">
        {children}
        <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
          <button
            type="button"
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
            onClick={onSave}
          >
            Save
          </button>
          <button
            type="button"
            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
            onClick={onClose}
          >
            Cancel
          </button>
        </div>
      </div>
    </Lightbox>
  );
};

const EditableField = ({ label, value, onEdit, editComponent }) => (
  <div className="bg-white p-3 rounded-lg shadow-md transition-all duration-300 hover:shadow-lg">
    <div className="flex items-center justify-between mb-1">
      <div className="flex items-center">
        <label className="ml-2 text-xs font-medium text-gray-600">{label}</label>
      </div>
      {onEdit && (
        <button 
          onClick={onEdit}
          className="p-1 rounded-full bg-blue-100 text-blue-600 hover:bg-blue-200 transition-colors duration-200"
        >
          <Edit2 size={12} />
        </button>
      )}
    </div>
    {editComponent}
  </div>
);

export default CaseDetailPage;