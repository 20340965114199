import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useWorkflow } from '../contexts/WorkflowContext';
import { useAppContext } from '../AppContext';
import { usePermissions } from '../contexts/PermissionsContext';
import { useAuth } from '../AuthContext';
import { Plus, Edit2, Trash2, Workflow, ArrowRight, User, Settings, CheckCircle, AlertTriangle, Save, ChevronLeft, ChevronRight, ChevronDown, Users } from 'lucide-react';
import LightboxForm from './LightboxForm';
import { collection, getDocs, query, where, doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { Accordion, AccordionItem, AccordionTrigger, AccordionContent } from './ui/accordion';
import { Card, CardContent, CardHeader, CardTitle } from './ui/card';


const pageName = 'Manage Workflows';
const ITEMS_PER_PAGE = 10;

const Workflows = () => {
  const { 
    workflows, 
    error, 
    isLoading: workflowsLoading,
    createWorkflow,
    updateWorkflow,
    deleteWorkflow,
    setDefaultWorkflow,
    fetchWorkflows, 
    defaultWorkflows,
    fetchDefaultWorkflows,
    setDefaultWorkflows,
    defaultWorkflowUsers,
    setDefaultWorkflowUsers,
    setError
  } = useWorkflow();
  const { currentCompany, currentUser } = useAppContext();
  const { user: authUser } = useAuth();
  const { permissions, loading: permissionsLoading } = usePermissions();
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [users, setUsers] = useState({});
  const [editingWorkflow, setEditingWorkflow] = useState(null);
  const [hasDefaultWorkflowChanges, setHasDefaultWorkflowChanges] = useState(false);
  const [usersLoading, setUsersLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [tempDefaultWorkflows, setTempDefaultWorkflows] = useState({});
  const [isMobile, setIsMobile] = useState(window.innerWidth < 640);

  useEffect(() => {
    setTempDefaultWorkflows(defaultWorkflows);
  }, [defaultWorkflows]);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 640);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const filteredAndSortedWorkflows = useMemo(() => {
    return workflows
      .filter(workflow => workflow.name.toLowerCase().includes(searchTerm.toLowerCase()))
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [workflows, searchTerm]);

  const totalPages = Math.ceil(filteredAndSortedWorkflows.length / ITEMS_PER_PAGE);

  const paginatedWorkflows = useMemo(() => {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    return filteredAndSortedWorkflows.slice(startIndex, startIndex + ITEMS_PER_PAGE);
  }, [filteredAndSortedWorkflows, currentPage]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleCreateWorkflow = useCallback(async (newWorkflow) => {
    await createWorkflow(newWorkflow);
    setIsLightboxOpen(false);
    await fetchWorkflows();
  }, [createWorkflow, fetchWorkflows]);

  const handleUpdateWorkflow = useCallback(async (updatedWorkflow) => {
    await updateWorkflow(updatedWorkflow.id, updatedWorkflow);
    setEditingWorkflow(null);
    setIsLightboxOpen(false);
    await fetchWorkflows();
    await fetchDefaultWorkflows();
  }, [updateWorkflow, fetchWorkflows, fetchDefaultWorkflows]);

  const openEditLightbox = useCallback((workflow) => {
    setEditingWorkflow(workflow);
    setIsLightboxOpen(true);
  }, []);

  const fetchWorkflowUsers = useCallback(async (caseType, workflowId) => {
    if (!currentCompany) return;
    try {
      const workflowRef = doc(db, 'companies', currentCompany.id, 'workflows', workflowId);
      const workflowDoc = await getDoc(workflowRef);
      if (workflowDoc.exists()) {
        const workflowData = workflowDoc.data();
        const workflowUsers = await Promise.all(workflowData.steps.map(async (step) => {
          if (users[step.approver]) {
            return users[step.approver];
          } else {
            const userDoc = await getDoc(doc(db, 'users', step.approver));
            if (userDoc.exists()) {
              const userData = userDoc.data();
              return `${userData.firstName} ${userData.lastName}`;
            }
            return step.approver;
          }
        }));
        setDefaultWorkflowUsers(prev => ({ ...prev, [caseType]: workflowUsers }));
      }
    } catch (error) {
      console.error("Error fetching workflow users:", error);
    }
  }, [currentCompany, users, setDefaultWorkflowUsers]);

  const handleSetDefaultWorkflow = useCallback((caseType, workflowId) => {
    setTempDefaultWorkflows(prev => ({ ...prev, [caseType]: workflowId }));
    setHasDefaultWorkflowChanges(true);
  }, []);

  const handleSaveDefaultWorkflows = useCallback(async () => {
    if (!currentCompany) return;
    try {
      const companyRef = doc(db, 'companies', currentCompany.id);
      await updateDoc(companyRef, { defaultWorkflows: tempDefaultWorkflows });
      setDefaultWorkflows(tempDefaultWorkflows);
      console.log("Default workflows saved successfully");
      setHasDefaultWorkflowChanges(false);
    } catch (error) {
      console.error("Error saving default workflows:", error);
      setError("Failed to save default workflows.");
    }
  }, [currentCompany, tempDefaultWorkflows, setDefaultWorkflows, setError]);

  const memoizedFetchWorkflows = useCallback(() => {
    if (!workflowsLoading && workflows.length === 0) {
      console.log('Calling fetchWorkflows from Workflows component');
      fetchWorkflows();
    }
  }, [fetchWorkflows, workflowsLoading, workflows.length]);

  const memoizedFetchDefaultWorkflows = useCallback(() => {
    if (!workflowsLoading && Object.keys(defaultWorkflows).length === 0) {
      console.log('Calling fetchDefaultWorkflows from Workflows component');
      fetchDefaultWorkflows();
    }
  }, [fetchDefaultWorkflows, workflowsLoading, defaultWorkflows]);

  useEffect(() => {
    console.log('useEffect triggered', { authUser, currentCompany, permissions, workflowsLoading });
    document.title = `${pageName} - HR Case Hub`;
    
    if (authUser && currentCompany && permissions?.viewWorkflows && !workflowsLoading) {
      memoizedFetchWorkflows();
      memoizedFetchDefaultWorkflows();
    }
  }, [authUser, currentCompany, permissions, workflowsLoading, memoizedFetchWorkflows, memoizedFetchDefaultWorkflows]);

  useEffect(() => {
    const fetchUsers = async () => {
      if (currentCompany) {
        setUsersLoading(true);
        try {
          const usersRef = collection(db, 'users');
          const q = query(usersRef, where('companyId', '==', currentCompany.id));
          const querySnapshot = await getDocs(q);
          const fetchedUsers = {};
          querySnapshot.forEach((doc) => {
            const userData = doc.data();
            fetchedUsers[doc.id] = `${userData.firstName} ${userData.lastName}`;
          });
          setUsers(fetchedUsers);
        } catch (error) {
          console.error("Error fetching users:", error);
        } finally {
          setUsersLoading(false);
        }
      }
    };

    fetchUsers();
  }, [currentCompany]);

  const handleDeleteWorkflow = useCallback((workflowId) => {
    if (window.confirm('Are you sure you want to delete this workflow?')) {
      deleteWorkflow(workflowId);
    }
  }, [deleteWorkflow]);

  const WorkflowCard = ({ workflow }) => (
    <Card className="mb-4">
      <CardHeader>
        <CardTitle className="text-lg">{workflow.name}</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="flex items-center space-x-1 mb-2">
          {workflow.steps.map((_, index) => (
            <React.Fragment key={index}>
              <User size={12} className="text-gray-400" />
              {index < workflow.steps.length - 1 && (
                <ArrowRight size={12} className="text-gray-400" />
              )}
            </React.Fragment>
          ))}
          <CheckCircle size={12} className="text-green-500" />
        </div>
        <div className="flex justify-end space-x-2">
          {permissions.editWorkflows && (
            <button
              onClick={() => openEditLightbox(workflow)}
              className="text-blue-500 hover:text-blue-600 focus:outline-none transition duration-300 ease-in-out"
            >
              <Edit2 size={18} />
            </button>
          )}
          {permissions.deleteWorkflows && (
            <button
              onClick={() => handleDeleteWorkflow(workflow.id)}
              className="text-red-500 hover:text-red-600 focus:outline-none transition duration-300 ease-in-out"
            >
              <Trash2 size={18} />
            </button>
          )}
        </div>
      </CardContent>
    </Card>
  );

  if (!authUser || !currentCompany || permissionsLoading) {
    console.log('Loading user, company, or permissions data...');
    return <div className="text-gray-600 p-4">Loading user and company data...</div>;
  }

  if (!permissions?.viewWorkflows) {
    console.log('No permission to view workflows');
    return <div className="text-gray-600 p-4">You don't have permission to view workflows.</div>;
  }

  if (workflowsLoading) {
    console.log('Rendering loading state');
    return <div className="text-gray-600 p-4">Loading workflows...</div>;
  }

  if (error) {
    console.log('Error:', error);
    return <div className="text-red-500 p-4">{error}</div>;
  }

  if (workflows.length === 0 && !workflowsLoading) {
    console.log('No workflows found');
    return <div className="text-gray-600 p-4">No workflows found.</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="bg-gradient-to-r from-blue-500 to-indigo-600 p-4 mb-6 rounded-lg shadow-lg">
        <div className="flex flex-col sm:flex-row justify-between items-center">
          <h1 className="text-2xl font-bold text-white flex items-center mb-4 sm:mb-0">
            <Workflow className="mr-2" size={24} />
            {pageName}
          </h1>
          {!isMobile && (
            <button
              onClick={() => setIsLightboxOpen(true)}
              className="bg-white text-indigo-600 hover:bg-indigo-100 font-semibold py-2 px-4 rounded-md transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50 flex items-center w-full sm:w-auto justify-center"
            >
              <Plus className="mr-2" size={20} />
              Add New Workflow
            </button>
          )}
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        <div className="bg-white shadow-md rounded-lg p-6">
          <h2 className="text-xl font-semibold mb-4 flex items-center">
            <Workflow className="mr-2 text-indigo-600" size={24} />
            Existing Workflows
          </h2>
          <div className="mb-4">
            <input
              type="text"
              placeholder="Search workflows..."
              className="w-full p-2 border rounded-md"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
          {paginatedWorkflows.length === 0 ? (
            <p className="text-gray-600">No workflows found.</p>
          ) : isMobile ? (
            paginatedWorkflows.map((workflow) => (
              <WorkflowCard key={workflow.id} workflow={workflow} />
            ))
          ) : (
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Workflow Name
                    </th>
                    <th scope="col" className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Steps
                    </th>
                    <th scope="col" className="px-3 py-2 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {paginatedWorkflows.map((workflow) => (
                    <tr key={workflow.id} className="hover:bg-gray-50">
                      <td className="px-3 py-2 whitespace-nowrap">
                        <div className="text-sm font-medium text-gray-900">{workflow.name}</div>
                      </td>
                      <td className="px-3 py-2 hidden sm:table-cell">
                        <div className="flex items-center space-x-1">
                          {workflow.steps.map((_, index) => (
                            <React.Fragment key={index}>
                              <User size={12} className="text-gray-400" />
                              {index < workflow.steps.length - 1 && (
                                <ArrowRight size={12} className="text-gray-400" />
                              )}
                            </React.Fragment>
                          ))}
                          <CheckCircle size={12} className="text-green-500" />
                        </div>
                      </td>
                      <td className="px-3 py-2 whitespace-nowrap text-right text-sm font-medium">
                        {permissions.editWorkflows && (
                          <button
                            onClick={() => openEditLightbox(workflow)}
                            className="text-blue-500 hover:text-blue-600 mr-2 focus:outline-none transition duration-300 ease-in-out"
                          >
                            <Edit2 size={18} />
                          </button>
                        )}
                        {permissions.deleteWorkflows && (
                          <button
                            onClick={() => handleDeleteWorkflow(workflow.id)}
                            className="text-red-500 hover:text-red-600 focus:outline-none transition duration-300 ease-in-out"
                          >
                            <Trash2 size={18} />
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          <div className="mt-4 flex flex-col sm:flex-row justify-between items-center">
            <div className="text-sm text-gray-700 mb-2 sm:mb-0">
              Showing <span className="font-medium">{(currentPage - 1) * ITEMS_PER_PAGE + 1}</span> to{' '}
              <span className="font-medium">
                {Math.min(currentPage * ITEMS_PER_PAGE, filteredAndSortedWorkflows.length)}
              </span>{' '}
              of <span className="font-medium">{filteredAndSortedWorkflows.length}</span> workflows
            </div>
            <div className="flex-1 flex justify-center sm:justify-end">
              <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                <button
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                  className={`relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 ${
                    currentPage === 1 ? 'cursor-not-allowed' : ''
                  }`}
                >
                  <span className="sr-only">Previous</span>
                  <ChevronLeft className="h-5 w-5" aria-hidden="true" />
                </button>
                {/* Add page numbers here if needed */}
                <button
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                  className={`relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 ${
                    currentPage === totalPages ? 'cursor-not-allowed' : ''
                  }`}
                >
                  <span className="sr-only">Next</span>
                  <ChevronRight className="h-5 w-5" aria-hidden="true" />
                </button>
              </nav>
            </div>
          </div>
        </div>

        <div className="bg-white shadow-md rounded-lg p-6">
          <h2 className="text-xl font-semibold mb-4 flex items-center">
            <AlertTriangle className="mr-2 text-yellow-500" size={24} />
            Default Workflows
          </h2>
          <p className="text-gray-600 mb-4">
            Assign default workflows for each case type.
          </p>
          {isMobile ? (
            <Accordion type="single" collapsible className="w-full">
              {['Performance Improvement Plan', 'Investigation', 'Corrective Action'].map((caseType) => (
                <AccordionItem key={caseType} value={caseType}>
                  <AccordionTrigger>{caseType}</AccordionTrigger>
                  <AccordionContent>
                    <select
                      value={tempDefaultWorkflows[caseType] || ''}
                      onChange={(e) => handleSetDefaultWorkflow(caseType, e.target.value)}
                      className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                    >
                      <option value="">Select a workflow</option>
                      {workflows.map((workflow) => (
                        <option key={workflow.id} value={workflow.id}>
                          {workflow.name}
                        </option>
                      ))}
                    </select>
                  </AccordionContent>
                </AccordionItem>
              ))}
            </Accordion>
          ) : (
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Case Type
                    </th>
                    <th scope="col" className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Default Workflow
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {['Performance Improvement Plan', 'Investigation', 'Corrective Action'].map((caseType) => (
                    <tr key={caseType} className="hover:bg-gray-50">
                      <td className="px-3 py-2 whitespace-nowrap text-sm font-medium text-gray-900">{caseType}</td>
                      <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-500">
                        <select
                          value={tempDefaultWorkflows[caseType] || ''}
                          onChange={(e) => handleSetDefaultWorkflow(caseType, e.target.value)}
                          className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                        >
                          <option value="">Select a workflow</option>
                          {workflows.map((workflow) => (
                            <option key={workflow.id} value={workflow.id}>
                              {workflow.name}
                            </option>
                          ))}
                        </select>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          {hasDefaultWorkflowChanges && (
            <div className="mt-4 flex justify-end">
              <button
                onClick={handleSaveDefaultWorkflows}
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 w-full sm:w-auto justify-center"
              >
                <Save className="mr-2" size={20} />
                Save Default Workflows
              </button>
            </div>
          )}
        </div>
      </div>

      {isMobile && (
        <button
          onClick={() => setIsLightboxOpen(true)}
          className="fixed bottom-6 right-6 bg-indigo-600 text-white rounded-full p-4 shadow-lg hover:bg-indigo-700 transition duration-300 ease-in-out"
        >
          <Plus size={24} />
        </button>
      )}

      <LightboxForm
        isOpen={isLightboxOpen}
        onClose={() => {
          setIsLightboxOpen(false);
          setEditingWorkflow(null);
        }}
        onSubmit={editingWorkflow ? handleUpdateWorkflow : handleCreateWorkflow}
        currentCompany={currentCompany}
        editingWorkflow={editingWorkflow}
      />
    </div>
  );
};

export default React.memo(Workflows);