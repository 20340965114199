import React, { useState } from 'react';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { db, auth } from '../firebase';
import { AlertCircle, X, UserPlus } from 'lucide-react';
import { generateStrongPassword } from '../utils/userManagement';
import { getFunctions, httpsCallable } from 'firebase/functions';

const AddUserForm = ({ currentCompany, onSuccess, onClose }) => {
  const [newUser, setNewUser] = useState({
    email: '',
    role: 'user',
    firstName: '',
    lastName: '',
    jobTitle: '',
    isActive: true
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewUser(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    try {
      const generatedPassword = generateStrongPassword();
      const userCredential = await createUserWithEmailAndPassword(auth, newUser.email, generatedPassword);
      const { uid } = userCredential.user;
      
      const userRef = doc(db, 'companies', currentCompany.id, 'users', uid);
      await setDoc(userRef, {
        firstName: newUser.firstName,
        lastName: newUser.lastName,
        jobTitle: newUser.jobTitle,
        role: newUser.role,
        isActive: newUser.isActive,
        email: newUser.email,
        passwordChangeRequired: true
      });

      // Send welcome email with login information
      const functions = getFunctions();
      const sendWelcomeEmail = httpsCallable(functions, 'sendWelcomeEmail');
      await sendWelcomeEmail({
        email: newUser.email,
        firstName: newUser.firstName,
        lastName: newUser.lastName,
        password: generatedPassword
      });

      onSuccess('New user added successfully! Login information sent to user\'s email.');
      onClose();
    } catch (error) {
      console.error('Error adding new user:', error);
      setError('Failed to add new user: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="flex items-center justify-center mb-6">
        <UserPlus className="h-8 w-8 text-indigo-600 mr-2" />
        <h2 className="text-2xl font-bold text-gray-900">Add New User</h2>
      </div>
      <form onSubmit={handleSubmit} className="space-y-4">
        {error && (
          <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4 flex justify-between items-center" role="alert">
            <div>
              <p className="font-bold">Error</p>
              <p>{error}</p>
            </div>
            <button onClick={() => setError('')} className="text-red-700">
              <X size={24} />
            </button>
          </div>
        )}
        <div>
          <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">First Name</label>
          <input type="text" id="firstName" name="firstName" value={newUser.firstName} onChange={handleInputChange} required className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2" />
        </div>
        <div>
          <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">Last Name</label>
          <input type="text" id="lastName" name="lastName" value={newUser.lastName} onChange={handleInputChange} required className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2" />
        </div>
        <div>
          <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
          <input type="email" id="email" name="email" value={newUser.email} onChange={handleInputChange} required className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2" />
        </div>
        <div>
          <label htmlFor="jobTitle" className="block text-sm font-medium text-gray-700">Job Title</label>
          <input type="text" id="jobTitle" name="jobTitle" value={newUser.jobTitle} onChange={handleInputChange} required className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2" />
        </div>
        <div>
          <label htmlFor="role" className="block text-sm font-medium text-gray-700">Role</label>
          <select id="role" name="role" value={newUser.role} onChange={handleInputChange} className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2">
            <option value="user">User</option>
            <option value="admin">Admin</option>
          </select>
        </div>
        <div className="flex items-center">
          <input type="checkbox" id="isActive" name="isActive" checked={newUser.isActive} onChange={(e) => setNewUser(prev => ({ ...prev, isActive: e.target.checked }))} className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded" />
          <label htmlFor="isActive" className="ml-2 block text-sm text-gray-900">Active Account</label>
        </div>
        <div className="flex flex-col space-y-2 sm:flex-row sm:space-y-0 sm:space-x-2">
          <button
            type="button"
            onClick={onClose}
            className="w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={loading}
            className="w-full px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            {loading ? 'Adding...' : 'Add User'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddUserForm;
