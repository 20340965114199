import React, { useState, useCallback } from 'react';
import { Upload, File, Trash, Download } from 'lucide-react';
import { storage } from '../firebase';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';

const FileUploadSection = ({ files, onUpdate, companyId, caseId, readOnly = false }) => {
  const [uploading, setUploading] = useState(false);

  const handleFileUpload = useCallback(async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setUploading(true);
    const fileRef = ref(storage, `companies/${companyId}/cases/${caseId}/${file.name}`);

    try {
      const snapshot = await uploadBytes(fileRef, file);
      const downloadURL = await getDownloadURL(snapshot.ref);
      const newFile = {
        name: file.name,
        url: downloadURL,
        type: file.type,
        size: file.size,
        uploadDate: new Date().toISOString()
      };
      onUpdate([...(files || []), newFile]);
    } catch (error) {
      console.error("Error uploading file:", error);
      // Handle error (e.g., show error message to user)
    } finally {
      setUploading(false);
    }
  }, [companyId, caseId, files, onUpdate]);

  const deleteFile = useCallback(async (fileToDelete) => {
    const fileRef = ref(storage, fileToDelete.url);
    try {
      await deleteObject(fileRef);
      const updatedFiles = files.filter(file => file.url !== fileToDelete.url);
      onUpdate(updatedFiles);
    } catch (error) {
      console.error("Error deleting file:", error);
      // Handle error (e.g., show error message to user)
    }
  }, [files, onUpdate]);

  return (
    <div className="bg-white shadow rounded-lg p-4 sm:p-6 overflow-hidden">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-base sm:text-lg font-medium text-gray-900 flex items-center min-w-0">
          <Upload className="w-5 h-5 sm:w-6 sm:h-6 mr-2 flex-shrink-0 text-indigo-500" />
          <span className="truncate">Uploaded Files</span>
        </h3>
        {!readOnly && (
          <label className="inline-flex items-center px-2 sm:px-3 py-1 border border-transparent text-xs sm:text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 flex-shrink-0 ml-2 cursor-pointer">
            <Upload className="w-4 h-4 sm:w-5 sm:h-5 mr-1" />
            Upload File
            <input type="file" className="hidden" onChange={handleFileUpload} disabled={uploading} />
          </label>
        )}
      </div>
      {files && files.length > 0 ? (
        <div className="space-y-3">
          {files.map((file, index) => (
            <div key={index} className="bg-gray-50 rounded-lg p-3 flex items-center justify-between">
              <div className="flex items-center space-x-3">
                <File className="w-5 h-5 text-indigo-500" />
                <div>
                  <p className="text-sm font-medium text-gray-900">{file.name}</p>
                  <p className="text-xs text-gray-500">{new Date(file.uploadDate).toLocaleString()}</p>
                </div>
              </div>
              <div className="flex space-x-2">
                <a
                  href={file.url}
                  download
                  className="text-gray-400 hover:text-indigo-600 transition-colors duration-300"
                >
                  <Download className="w-5 h-5" />
                </a>
                {!readOnly && (
                  <button
                    onClick={() => deleteFile(file)}
                    className="text-gray-400 hover:text-red-600 transition-colors duration-300"
                  >
                    <Trash className="w-5 h-5" />
                  </button>
                )}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="text-center py-4 bg-gray-50 rounded-lg">
          <div className="flex items-center justify-center text-indigo-400">
            <File className="w-5 h-5 mr-2" />
            <h3 className="text-sm font-medium text-gray-900">No files uploaded</h3>
          </div>
          <p className="text-xs text-gray-500 mt-1">
            {readOnly
              ? "There are no files to display."
              : "Click 'Upload File' to add documents."}
          </p>
        </div>
      )}
      {uploading && (
        <div className="mt-4 text-center">
          <p className="text-sm text-gray-600">Uploading file...</p>
        </div>
      )}
    </div>
  );
};

export default FileUploadSection;