import React, { createContext, useState, useContext, useEffect } from 'react';
import { onAuthStateChanged, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { auth, db } from './firebase';
import { getSubscriptionDetails } from './utils/stripe'; // Updated import

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        const userData = await fetchUserData(firebaseUser);
        if (userData) {
          setUser(userData);
        } else {
          setUser(null);
        }
      } else {
        setUser(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const clearCache = () => {
    localStorage.clear();
  };

  const fetchUserData = async (firebaseUser) => {
    try {
      const userDocRef = doc(db, 'users', firebaseUser.uid);
      const userDocSnap = await getDoc(userDocRef);
      
      if (userDocSnap.exists()) {
        const userData = userDocSnap.data();
        console.log('User data fetched successfully');

        // Fetch company data if companyId exists
        if (userData.companyId) {
          const companyDocRef = doc(db, 'companies', userData.companyId);
          const companyDocSnap = await getDoc(companyDocRef);
          
          if (companyDocSnap.exists()) {
            const companyData = companyDocSnap.data();
            console.log('Company data fetched successfully');
            return { ...firebaseUser, ...userData, company: companyData };
          } else {
            console.log('Company document not found');
            return { ...firebaseUser, ...userData, company: null };
          }
        } else {
          console.log('User does not have a companyId');
          return { ...firebaseUser, ...userData, company: null };
        }
      } else {
        console.log('No user document found. User might be new.');
        return firebaseUser;
      }
    } catch (error) {
      console.error('Error fetching user or company data:', error);
      return null;
    }
  };

  const login = async (email, password) => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const userData = await fetchUserData(userCredential.user);
      if (userData) {
        setUser(userData);
      } else {
        throw new Error('Failed to fetch user data');
      }
    } catch (error) {
      console.error("Error signing in:", error);
      throw error;
    }
  };

  const logout = async () => {
    try {
      await signOut(auth);
      
      // Clear all local storage
      localStorage.clear();
      
      // Explicitly clear Stripe-related data
      localStorage.removeItem('stripeCustomerId');
      localStorage.removeItem('stripeSubscriptionId');
      
      // Clear any cached subscription details
      const keys = Object.keys(localStorage);
      for (let key of keys) {
        if (key.startsWith('subscriptionData_') || key.startsWith('companyData_') || key.startsWith('userData_')) {
          localStorage.removeItem(key);
        }
      }
      
      console.log('Local storage and Stripe data cleared on logout');
      
      // If you're using any in-memory cache for Stripe data, clear it here
      // For example, if you have a subscriptionCache:
      // subscriptionCache.clear();
      
    } catch (error) {
      console.error("Error signing out:", error);
      throw error;
    }
  };

  const value = { user, loading, login, logout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);