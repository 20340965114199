import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { useAppContext } from './AppContext';
import { AppProvider } from './AppContext';
import Dashboard from './Dashboard';
import AddCasePage from './AddCasePage';
import CaseDetailPage from './CaseDetailPage';
import EmployeesPage from './EmployeesPage';
import AddEmployeePage from './AddEmployeePage';
import EmployeeDetailPage from './EmployeeDetailPage';
import ImportEmployeesPage from './ImportEmployeesPage';
import ReportsPage from './ReportsPage';
import SettingsPage from './SettingsPage'; // Add this import
import LoginForm from './LoginForm';
import UserProfile from './UserProfile';
import AuthenticatedLayout from './AuthenticatedLayout';
import SuccessPage from './SuccessPage'; // New import
import AccountSetupPage from './AccountSetupPage'; // New import
import EmployeeManagementPage from './EmployeeManagementPage';
import CaseOverviewPage from './CaseOverviewPage';
import CaseSummaryReport from './reports/CaseSummaryReport'; // Updated import
import CaseStatusReportPage from './reports/CaseStatusReportPage';
import CaseOutcomeReportPage from './reports/CaseOutcomeReportPage';
import CaseloadReportPage from './reports/CaseloadReport';
import { PermissionsProvider } from './contexts/PermissionsContext';
import { AuthProvider } from './AuthContext';
import ErrorBoundary from './components/ErrorBoundary';
import { QueryClient, QueryClientProvider } from 'react-query';
import { usePermissions } from './contexts/PermissionsContext';
import ChangePasswordPage from './ChangePasswordPage';
import Workflows from './components/Workflows'; // Add this import
import { WorkflowProvider } from './contexts/WorkflowContext'; // Add this import
import { NotificationProvider } from './NotificationContext';
import LoadingSpinner from './components/LoadingSpinner'; // Add this import

// Create a client
const queryClient = new QueryClient();

function App() {
  return (
    <ErrorBoundary>
      <AuthProvider>
        <AppProvider>
          <NotificationProvider>
            <ErrorBoundary>
              <QueryClientProvider client={queryClient}>
                <PermissionsProvider>
                  <WorkflowProvider>
                    <Router>
                      <AppContent />
                    </Router>
                  </WorkflowProvider>
                </PermissionsProvider>
              </QueryClientProvider>
            </ErrorBoundary>
          </NotificationProvider>
        </AppProvider>
      </AuthProvider>
    </ErrorBoundary>
  );
}

function AppContent() {
  const { user, loading: authLoading } = useAuth();
  const { currentCompany, loading: appLoading, isInitialized } = useAppContext();
  const { loading: permissionsLoading } = usePermissions();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isLoading = authLoading || appLoading || permissionsLoading || !isInitialized;

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Routes>
      <Route path="/login" element={<LoginForm />} />
      <Route path="/success" element={<SuccessPage />} />
      <Route path="/account-setup" element={<AccountSetupPage />} />
      <Route path="/change-password" element={<ChangePasswordPage />} />

      {/* Protected routes */}
      {user ? (
        <Route element={<AuthenticatedLayout isMobile={isMobile} />}>
          <Route path="/" element={<Dashboard />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/cases" element={<CaseOverviewPage />} />
          <Route path="/cases/add" element={<AddCasePage />} />
          <Route path="/cases/:caseId" element={<CaseDetailPage />} />
          <Route path="/employees" element={<EmployeesPage />} />
          <Route path="/employees/manage" element={<EmployeeManagementPage />} />
          <Route path="/employees/:employeeId" element={<EmployeeDetailPage />} />
          <Route 
            path="/reports" 
            element={
              user.role === 'admin' || user.role === 'superuser' 
                ? <ReportsPage /> 
                : <Navigate to="/dashboard" replace />
            } 
          />
          <Route 
            path="/reports/case-summary" 
            element={
              user.role === 'admin' || user.role === 'superuser' 
                ? <CaseSummaryReport /> 
                : <Navigate to="/dashboard" replace />
            } 
          />
          <Route 
            path="/reports/case-status" 
            element={
              user.role === 'admin' || user.role === 'superuser' 
                ? <CaseStatusReportPage /> 
                : <Navigate to="/dashboard" replace />
            } 
          />
          <Route 
            path="/reports/case-outcome" 
            element={
              user.role === 'admin' || user.role === 'superuser' 
                ? <CaseOutcomeReportPage /> 
                : <Navigate to="/dashboard" replace />
            } 
          />
          <Route 
            path="/reports/caseload" 
            element={
              user.role === 'admin' || user.role === 'superuser' 
                ? <CaseloadReportPage /> 
                : <Navigate to="/dashboard" replace />
            } 
          />
          <Route path="/profile" element={<UserProfile />} />
          <Route 
            path="/settings" 
            element={
              user.role === 'admin' || user.role === 'superuser' 
                ? <SettingsPage /> 
                : <Navigate to="/dashboard" replace />
            } 
          />
          <Route 
            path="/workflows" 
            element={
              user.role === 'admin' || user.role === 'superuser' 
                ? <Workflows /> 
                : <Navigate to="/dashboard" replace />
            } 
          />
        </Route>
      ) : (
        <Route path="*" element={<Navigate to="/login" replace />} />
      )}
    </Routes>
  );
}

export default App;