import React, { useState, useCallback, useRef, useEffect, useMemo } from 'react';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { debounce } from 'lodash';
import { Check, AlertCircle, Printer, ChevronDown, Edit, FileText, Search, Clipboard } from 'lucide-react';
import InvolvedPartiesSection from '../shared/InvolvedPartiesSection';
import EventTrackerSection from '../shared/EventTrackerSection';
import Lightbox from '../shared/Lightbox';
import { useReactToPrint } from 'react-to-print';
import { Menu } from '@headlessui/react';
import { Editor } from '@tinymce/tinymce-react';
import ReviewWorkflowSection from '../shared/ReviewWorkflowSection';
import { useWorkflow } from '../contexts/WorkflowContext';
import FileUploadSection from '../shared/FileUploadSection';

const Investigation = React.memo(({ caseData, caseId, companyId, onUpdate, currentUser, users }) => {
  const { workflows, defaultWorkflows, fetchWorkflows, fetchDefaultWorkflows, updateCaseWorkflow, startWorkflow, approveWorkflowStep } = useWorkflow();
  const [localCaseData, setLocalCaseData] = useState(caseData);
  const [saved, setSaved] = useState(false);
  const [error, setError] = useState(null);
  const [editingField, setEditingField] = useState(null);
  const pdfRef = useRef(null);

  const memoizedWorkflows = useMemo(() => workflows, [workflows]);
  const memoizedDefaultWorkflows = useMemo(() => defaultWorkflows, [defaultWorkflows]);

  useEffect(() => {
    if (memoizedWorkflows.length === 0) {
      fetchWorkflows();
    }
    if (Object.keys(memoizedDefaultWorkflows).length === 0) {
      fetchDefaultWorkflows();
    }
  }, [fetchWorkflows, fetchDefaultWorkflows, memoizedWorkflows, memoizedDefaultWorkflows]);

  const handlePrintCase = useReactToPrint({
    content: () => pdfRef.current,
    documentTitle: `investigation_${caseId}.pdf`,
  });

  const autoSave = useMemo(() => 
    debounce(async (data) => {
      if (!caseId) {
        console.error('No caseId available for autosave');
        return;
      }
      console.log('Attempting to update document with caseId:', caseId);
      const caseRef = doc(db, 'companies', companyId, 'cases', caseId);
      
      const filteredData = Object.fromEntries(
        Object.entries(data).filter(([_, v]) => v !== undefined)
      );

      try {
        console.log('Attempting to update Firebase with:', JSON.stringify(filteredData));
        await setDoc(caseRef, filteredData, { merge: true });
        console.log('AutoSave successful');
        
        // Verify the update
        const updatedDoc = await getDoc(caseRef);
        if (updatedDoc.exists()) {
          console.log('Updated document data:', updatedDoc.data());
        } else {
          console.error('Document does not exist after update');
        }
        
        setSaved(true);
        setTimeout(() => setSaved(false), 2000);
      } catch (error) {
        console.error("Error updating case document:", error);
        setError("Failed to save case data. Please try again.");
      }
    }, 1000),
    [caseId, companyId]
  );

  const handleInputChange = useCallback((name, value) => {
    setLocalCaseData(prev => {
      const updatedData = { ...prev, [name]: value };
      console.log('Calling autoSave with:', updatedData);
      autoSave(updatedData);
      return updatedData;
    });
  }, [autoSave]);

  const handleInvolvedPartiesUpdate = useCallback((newInvolvedParties) => {
    setLocalCaseData(prev => {
      const updatedData = { ...prev, involvedParties: newInvolvedParties };
      autoSave(updatedData);
      return updatedData;
    });
  }, [autoSave]);

  const handleEventsUpdate = useCallback((newEvents) => {
    setLocalCaseData(prev => {
      const updatedData = { ...prev, events: newEvents };
      autoSave(updatedData);
      return updatedData;
    });
  }, [autoSave]);

  const handleFilesUpdate = useCallback((newFiles) => {
    setLocalCaseData(prev => {
      const updatedData = { ...prev, files: newFiles };
      autoSave(updatedData);
      return updatedData;
    });
  }, [autoSave]);

  const handleChangeWorkflow = useCallback(async (newWorkflowId) => {
    try {
      const updatedData = await updateCaseWorkflow(companyId, caseId, newWorkflowId);
      setLocalCaseData(prev => ({
        ...prev,
        ...updatedData
      }));
    } catch (error) {
      console.error("Error updating workflow:", error);
      setError("Failed to update workflow. Please try again.");
    }
  }, [companyId, caseId, updateCaseWorkflow]);

  const fetchCaseData = useCallback(async () => {
    try {
      const caseRef = doc(db, 'companies', companyId, 'cases', caseId);
      const caseSnap = await getDoc(caseRef);
      
      if (caseSnap.exists()) {
        const data = caseSnap.data();
        return {
          ...data,
          currentApprover: data.currentApprover || null
        };
      } else {
        console.error('No such case document!');
        return null;
      }
    } catch (error) {
      console.error('Error fetching case data:', error);
      throw error;
    }
  }, [companyId, caseId]);

  const handleStartWorkflow = useCallback(async () => {
    try {
      if (!users || users.length === 0) {
        console.error('Users data is not available');
        return;
      }

      const result = await startWorkflow(companyId, caseId, "Investigation", localCaseData.workflow, users);
      if (result.success) {
        console.log(result.message);
        const updatedCaseData = await fetchCaseData();
        if (updatedCaseData) {
          setLocalCaseData(updatedCaseData);
        }
      }
    } catch (error) {
      console.error('Error starting workflow:', error);
      setError('Failed to start workflow. Please try again.');
    }
  }, [companyId, caseId, localCaseData.workflow, users, startWorkflow, fetchCaseData]);

  const handleApproveStep = useCallback(async () => {
    if (!currentUser) {
      console.error('Current user is not available');
      setError('Unable to approve step. Please try again later.');
      return;
    }

    try {
      const result = await approveWorkflowStep(companyId, caseId, localCaseData.currentApprovalStep, currentUser.uid);
      if (result.success) {
        const updatedCaseData = await fetchCaseData();
        setLocalCaseData(updatedCaseData);
      }
    } catch (error) {
      console.error('Error approving workflow step:', error);
      setError('Failed to approve workflow step. Please try again.');
    }
  }, [companyId, caseId, localCaseData.currentApprovalStep, currentUser, approveWorkflowStep, fetchCaseData]);

  const PDFView = React.forwardRef(({ caseData }, ref) => (
    <div ref={ref} className="space-y-6 p-8">
      <h1 className="text-3xl font-bold">Investigation</h1>
      
      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">Case Information</h2>
        <table className="min-w-full divide-y divide-gray-200">
          <tbody className="bg-white divide-y divide-gray-200">
            <tr>
              <td className="px-6 py-4 whitespace-nowrap font-medium">Employee:</td>
              <td className="px-6 py-4 whitespace-nowrap">{caseData.employee}</td>
            </tr>
            <tr>
              <td className="px-6 py-4 whitespace-nowrap font-medium">Case Type:</td>
              <td className="px-6 py-4 whitespace-nowrap">Investigation</td>
            </tr>
            <tr>
              <td className="px-6 py-4 whitespace-nowrap font-medium">Assigned To:</td>
              <td className="px-6 py-4 whitespace-nowrap">{caseData.assignedToName}</td>
            </tr>
          </tbody>
        </table>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">Summary</h2>
        <div dangerouslySetInnerHTML={{ __html: caseData.summary }} />
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">Findings</h2>
        <div dangerouslySetInnerHTML={{ __html: caseData.findings }} />
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">Recommendations</h2>
        <div dangerouslySetInnerHTML={{ __html: caseData.recommendations }} />
      </section>
    </div>
  ));

  if (!currentUser) {
    return null;
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="mb-6 flex justify-between items-center">
        <h2 className="text-2xl font-bold text-gray-900">Investigation</h2>
        <Menu as="div" className="relative inline-block text-left">
          <Menu.Button className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
            Options
            <ChevronDown className="w-5 h-5 ml-2 -mr-1" aria-hidden="true" />
          </Menu.Button>
          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={handlePrintCase}
                    className={`${
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                    } group flex items-center px-4 py-2 text-sm w-full text-left`}
                  >
                    <Printer className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                    Print Case
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Menu>
      </div>

      {saved && (
        <div className="fixed bottom-4 right-4 bg-green-500 text-white px-4 py-2 rounded-md flex items-center shadow-lg">
          <Check className="mr-2" /> Saved
        </div>
      )}

      {error && (
        <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 rounded-md" role="alert">
          <div className="flex">
            <AlertCircle className="h-5 w-5 mr-2" />
            <p>{error}</p>
          </div>
        </div>
      )}

      <EditableSection
        title="Summary"
        fieldName="summary"
        value={localCaseData.summary}
        onEdit={handleInputChange}
        onClose={() => setEditingField(null)}
        isEditing={editingField === 'summary'}
        setIsEditing={() => setEditingField('summary')}
        icon={<FileText className="w-5 h-5 sm:w-6 sm:h-6 text-blue-500" />}
        placeholder="Provide a summary of the investigation here..."
      />

      <EditableSection
        title="Findings"
        fieldName="findings"
        value={localCaseData.findings}
        onEdit={handleInputChange}
        onClose={() => setEditingField(null)}
        isEditing={editingField === 'findings'}
        setIsEditing={() => setEditingField('findings')}
        icon={<Search className="w-5 h-5 sm:w-6 sm:h-6 text-green-500" />}
        placeholder="Detail the findings of the investigation here..."
      />

      <EditableSection
        title="Recommendations"
        fieldName="recommendations"
        value={localCaseData.recommendations}
        onEdit={handleInputChange}
        onClose={() => setEditingField(null)}
        isEditing={editingField === 'recommendations'}
        setIsEditing={() => setEditingField('recommendations')}
        icon={<Clipboard className="w-5 h-5 sm:w-6 sm:h-6 text-yellow-500" />}
        placeholder="Provide recommendations based on the investigation findings..."
      />

      <InvolvedPartiesSection
        involvedParties={localCaseData.involvedParties}
        companyId={companyId}
        onUpdate={handleInvolvedPartiesUpdate}
      />

      <EventTrackerSection
        events={localCaseData.events}
        onUpdate={handleEventsUpdate}
      />

      <FileUploadSection
        files={localCaseData.files}
        onUpdate={handleFilesUpdate}
        companyId={companyId}
        caseId={caseId}
      />

      <ReviewWorkflowSection
        workflow={localCaseData.workflow}
        currentStep={localCaseData.currentApprovalStep}
        caseType="Investigation"
        onStartWorkflow={handleStartWorkflow}
        onApproveStep={handleApproveStep}
        approvals={localCaseData.approvals || []}
        currentUser={currentUser}
        currentApprover={localCaseData.currentApprover}
        onChangeWorkflow={handleChangeWorkflow}
        availableWorkflows={memoizedWorkflows}
        users={users}
      />

      {/* Hidden PDF view */}
      <div style={{ display: 'none' }}>
        <PDFView ref={pdfRef} caseData={localCaseData} />
      </div>
    </div>
  );
});


const EditableSection = ({ title, fieldName, value, onEdit, onClose, isEditing, setIsEditing, icon, placeholder }) => {
  const [localValue, setLocalValue] = useState(value || '');

  useEffect(() => {
    setLocalValue(value || '');
  }, [value]);

  const handleClose = () => {
    if (localValue !== value) {
      onEdit(fieldName, localValue || '');
    }
    onClose();
  };

  const handleEditorChange = (content) => {
    setLocalValue(content);
  };

  return (
    <div className="mb-6">
      <div className="flex items-center justify-between mb-2">
        <div className="flex items-center">
          {icon}
          <h3 className="text-lg font-medium text-gray-900 ml-2">{title}</h3>
        </div>
        <button
          onClick={() => setIsEditing(true)}
          className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <Edit className="h-4 w-4 mr-1" />
          Edit
        </button>
      </div>
      <div className="bg-gray-50 rounded-lg p-4">
        {value ? (
          <div className="prose max-w-none text-sm text-gray-700" dangerouslySetInnerHTML={{ __html: value }} />
        ) : (
          <div className="text-center">
            <div className="flex items-center justify-center text-indigo-400">
              {React.cloneElement(icon, { className: "w-5 h-5 mr-2" })}
              <h3 className="text-sm font-medium text-gray-900">Add {title}</h3>
            </div>
            <p className="text-xs text-gray-500 mt-1">
              Click 'Edit' to provide details for {title.toLowerCase()}.
            </p>
          </div>
        )}
      </div>
      {isEditing && (
        <LightboxEditor
          isOpen={isEditing}
          onClose={() => {
            setIsEditing(false);
            onClose();
          }}
          title={title}
          value={value}
          onEdit={onEdit}
          fieldName={fieldName}
          placeholder={placeholder}
        />
      )}
    </div>
  );
};

const LightboxEditor = ({ isOpen, onClose, title, value, onEdit, fieldName, placeholder }) => {
  const [localValue, setLocalValue] = useState(value || '');

  useEffect(() => {
    setLocalValue(value || '');
  }, [value]);

  const handleClose = () => {
    if (localValue !== value) {
      onEdit(fieldName, localValue || '');
    }
    onClose();
  };

  const handleEditorChange = (content) => {
    setLocalValue(content);
  };

  return (
    <Lightbox isOpen={isOpen} onClose={handleClose} title={title} maxWidth="max-w-4xl">
      <div className="relative">
        <Editor
          apiKey="s6m55belr5vylvppptz7d16wyge9vizqs1qgptzxxbmy629m"
          value={localValue}
          init={{
            height: 400,
            menubar: false,
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount'
            ],
            toolbar:
              'undo redo | formatselect | bold italic backcolor | \
              alignleft aligncenter alignright alignjustify | \
              bullist numlist outdent indent | removeformat | help',
            placeholder: placeholder
          }}
          onEditorChange={handleEditorChange}
        />
      </div>
      <div className="mt-4 flex justify-end">
        <button
          onClick={handleClose}
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Save & Close
        </button>
      </div>
    </Lightbox>
  );
};

export default Investigation;