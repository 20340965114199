import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useAppContext } from './AppContext';
import { useAuth } from './AuthContext';
import { collection, query, where, getDocs, limit } from 'firebase/firestore';
import { db } from './firebase';
import { Link, useNavigate } from 'react-router-dom';
import { Plus, FileText, AlertCircle, CheckCircle, Clock, Users, BarChart2, Settings, Briefcase, Bell } from 'lucide-react';

const logger = {
  log: (...args) => process.env.NODE_ENV !== 'production' && console.log(...args),
  error: (...args) => console.error(...args),
};

const fetchEmployeeData = async (employeeIds, currentCompany) => {
  const employeesRef = collection(db, 'companies', currentCompany.id, 'employees');  
  const employeesQuery = query(employeesRef, where('__name__', 'in', employeeIds));
  const employeesSnapshot = await getDocs(employeesQuery);
  return employeesSnapshot.docs.reduce((acc, doc) => {
    acc[doc.id] = doc.data();
    return acc;
  }, {});
};

const Dashboard = React.memo(() => {
  const { currentCompany, user } = useAppContext();
    const [cases, setCases] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [metrics, setMetrics] = useState({ totalCases: 0, openCases: 0, closedCases: 0, inProgressCases: 0 });
  const navigate = useNavigate();
  const [pendingApprovals, setPendingApprovals] = useState([]);
  const [dashboardData, setDashboardData] = useState(null);

  const getStatusColor = (status) => {
    switch (status) {
      case 'Open':
        return 'bg-green-100 text-green-800';
      case 'In Progress':
        return 'bg-blue-100 text-blue-800';
      case 'Pending Review':
        return 'bg-yellow-100 text-yellow-800';
      case 'Closed':
        return 'bg-gray-100 text-gray-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const processCaseData = useMemo(() => (casesData) => {
    const sortedCases = casesData.sort((a, b) => new Date(b.lastUpdate) - new Date(a.lastUpdate));
    const open = sortedCases.filter(c => c.status === 'Open').length;
    const closed = sortedCases.filter(c => c.status === 'Closed').length;
    const inProgress = sortedCases.filter(c => c.status === 'In Progress').length;
    return {
      sortedCases,
      metrics: {
        totalCases: sortedCases.length,
        openCases: open,
        closedCases: closed,
        inProgressCases: inProgress
      }
    };
  }, []);

  const processData = useCallback((data, employeesData) => data.map(caseItem => ({
    ...caseItem,
    employeeName: caseItem.employee && employeesData[caseItem.employee]
      ? `${employeesData[caseItem.employee].firstName} ${employeesData[caseItem.employee].lastName}`
      : 'Employee Not Assigned'
  })), []);

  const fetchDashboardData = useCallback(async () => {
    if (!currentCompany || !user || !user.uid || dashboardData) return;

    logger.log("Fetching dashboard data. Company:", currentCompany.id, "User:", user.uid);
    try {
      setLoading(true);
      
      const casesRef = collection(db, 'companies', currentCompany.id, 'cases');
      const combinedQuery = query(
        casesRef,
        where("assignedTo", "==", user.uid),
        limit(20)
      );
      const querySnapshot = await getDocs(combinedQuery);
      
      const [casesData, pendingApprovalsData] = querySnapshot.docs.reduce((acc, doc) => {
        const data = { id: doc.id, ...doc.data() };
        if (data.currentApprover === user.uid && ["In Progress", "Pending Approval"].includes(data.status)) {
          acc[1].push(data);
        } else {
          acc[0].push(data);
        }
        return acc;
      }, [[], []]);

      const employeeIds = new Set([
        ...casesData.map(c => c.employee),
        ...pendingApprovalsData.map(c => c.employee)
      ].filter(Boolean));

      const employeesData = await fetchEmployeeData(Array.from(employeeIds), currentCompany);

      const processedCases = processData(casesData, employeesData);
      const processedPendingApprovals = processData(pendingApprovalsData, employeesData);

      const { sortedCases, metrics } = processCaseData(processedCases);
      setCases(sortedCases);
      setMetrics(metrics);
      setPendingApprovals(processedPendingApprovals);
      setDashboardData({ sortedCases, metrics, processedPendingApprovals });
    } catch (err) {
      logger.error("Error fetching dashboard data:", err);
      setError("Failed to fetch dashboard data: " + err.message);
    } finally {
      setLoading(false);
    }
  }, [currentCompany, user, processCaseData, processData, dashboardData]);

  useEffect(() => {
    if (!currentCompany || !user || !user.uid || dashboardData) return;
    logger.log("Dashboard useEffect triggered. CurrentCompany:", !!currentCompany, "User:", !!user, "User UID:", user?.uid);
    fetchDashboardData();
  }, [currentCompany, user, fetchDashboardData, dashboardData]);



  const MetricCard = React.memo(({ title, value, icon: Icon, color }) => (
    <div className={`bg-${color}-100 rounded-lg shadow-md p-4 flex items-center`}>
      <div className={`rounded-full bg-${color}-200 p-2 mr-3`}>
        <Icon className={`h-6 w-6 sm:h-8 sm:w-8 text-${color}-600`} />
      </div>
      <div>
        <h3 className={`text-sm sm:text-lg font-semibold text-${color}-800`}>{title}</h3>
        <p className={`text-lg sm:text-2xl font-bold text-${color}-900`}>{value}</p>
      </div>
    </div>
  ));

  const memoizedMetrics = useMemo(() => (
    <div className="grid grid-cols-2 lg:grid-cols-4 gap-3 sm:gap-4 mb-4 sm:mb-6">
      <MetricCard title="Total Cases" value={metrics.totalCases} icon={FileText} color="blue" />
      <MetricCard title="Open Cases" value={metrics.openCases} icon={AlertCircle} color="yellow" />
      <MetricCard title="In Progress" value={metrics.inProgressCases} icon={Clock} color="orange" />
      <MetricCard title="Closed Cases" value={metrics.closedCases} icon={CheckCircle} color="green" />
    </div>
  ), [metrics]);

  const memoizedCaseList = useMemo(() => (
    <table className="min-w-full divide-y divide-gray-200">
      <thead className="bg-gray-50">
        <tr>
          <th className="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ID</th>
          <th className="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Employee</th>
          <th className="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Type</th>
          <th className="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {cases.map((caseItem) => (
          <tr key={caseItem.id} className="hover:bg-gray-50 cursor-pointer" onClick={() => navigate(`/cases/${caseItem.id}`)}>
            <td className="px-2 py-2 whitespace-nowrap text-xs sm:text-sm">{caseItem.referenceId}</td>
            <td className="px-2 py-2 whitespace-nowrap text-xs sm:text-sm">{caseItem.employeeName}</td>
            <td className="px-2 py-2 whitespace-nowrap text-xs sm:text-sm">{caseItem.type}</td>
            <td className="px-2 py-2 whitespace-nowrap text-xs sm:text-sm">
              <span className={`px-1.5 py-0.5 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusColor(caseItem.status)}`}>
                {caseItem.status}
              </span>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  ), [cases, navigate, getStatusColor]);

  const memoizedPendingApprovalsList = useMemo(() => (
    <table className="min-w-full divide-y divide-gray-200">
      <thead className="bg-gray-50">
        <tr>
          <th className="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ID</th>
          <th className="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Employee</th>
          <th className="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Type</th>
          <th className="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {pendingApprovals.map((caseItem) => (
          <tr key={caseItem.id}>
            <td className="px-2 py-2 whitespace-nowrap text-xs sm:text-sm">{caseItem.referenceId}</td>
            <td className="px-2 py-2 whitespace-nowrap text-xs sm:text-sm">{caseItem.employeeName}</td>
            <td className="px-2 py-2 whitespace-nowrap text-xs sm:text-sm">{caseItem.type}</td>
            <td className="px-2 py-2 whitespace-nowrap text-xs sm:text-sm">
              <button
                onClick={() => navigate(`/cases/${caseItem.id}`)}
                className="bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-1 px-2 rounded text-xs"
              >
                View Case
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  ), [pendingApprovals, navigate]);

  const QuickLinkCard = React.memo(({ title, icon: Icon, path, color }) => (
    <Link to={path} className={`bg-white rounded-lg shadow-md p-3 flex items-center hover:bg-${color}-50 transition-colors duration-200`}>
      <div className={`rounded-full bg-${color}-100 p-2 mr-2`}>
        <Icon className={`h-4 w-4 sm:h-6 sm:w-6 text-${color}-600`} />
      </div>
      <span className={`text-sm sm:text-base font-medium text-${color}-800 truncate`}>{title}</span>
    </Link>
  ));


  if (!currentCompany || !user) {
    return <div className="flex justify-center items-center h-screen">Loading user and company data...</div>;
  }

  if (loading) {
    return <div className="flex justify-center items-center h-screen">Loading cases...</div>;
  }

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  return (
    <div className="p-4 sm:p-6 bg-gray-100">
      <h1 className="text-xl sm:text-2xl md:text-3xl font-bold text-gray-900 mb-4 sm:mb-6">Welcome, {user.firstName}!</h1>
      
      {memoizedMetrics}

      <div className="mb-4 sm:mb-6">
        <h2 className="text-lg sm:text-xl font-semibold text-gray-900 mb-3">Quick Links</h2>
        <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-3">
          <QuickLinkCard title="New Case" icon={Plus} path="/cases/add" color="indigo" />
          <QuickLinkCard title="All Cases" icon={FileText} path="/cases" color="blue" />
          <QuickLinkCard title="Employees" icon={Users} path="/employees" color="green" />
          <QuickLinkCard title="Reports" icon={BarChart2} path="/reports" color="purple" />
          <QuickLinkCard title="Settings" icon={Settings} path="/settings" color="gray" />
        </div>
      </div>

      <div className="bg-white rounded-lg shadow-md p-3 sm:p-4">
        <h2 className="text-lg sm:text-xl font-semibold text-gray-900 mb-3 flex items-center">
          <Briefcase className="mr-2 text-indigo-600 h-5 w-5 sm:h-6 sm:w-6" />
          Your Cases
        </h2>
        {cases.length === 0 ? (
          <p className="text-gray-500 text-sm sm:text-base">You currently have no cases assigned to you.</p>
        ) : (
          <div className="overflow-x-auto">
            {memoizedCaseList}
          </div>
        )}
      </div>

      <div className="bg-white rounded-lg shadow-md p-3 sm:p-4 mt-4">
        <h2 className="text-lg sm:text-xl font-semibold text-gray-900 mb-3 flex items-center">
          <Bell className="mr-2 text-indigo-600 h-5 w-5 sm:h-6 sm:w-6" />
          Pending Approvals
        </h2>
        {pendingApprovals.length === 0 ? (
          <p className="text-gray-500 text-sm sm:text-base">You have no cases pending approval.</p>
        ) : (
          <div className="overflow-x-auto">
            {memoizedPendingApprovalsList}
          </div>
        )}
      </div>
    </div>
  );
});

export default Dashboard;


